.root {
  background: var(--color-button-secondary-default);
  border-radius: 12px;
  cursor: pointer;
  width: 100%;
}

.rootOutline {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 75.76%
  );
  height: 132px;
  position: absolute;
  width: 100%;
}

.labelWhite {
  color: white;
}

.valueWhite {
  color: white;
}

.container {
  padding: 12px 12px;
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 11;
}
.data {
  box-sizing: border-box;
  /*padding: 12px 0 0;*/
  position: relative;
  overflow: hidden;
  width: 100%;
}
.label {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  top: 0;
  left: 0;
}
.valueContainer {
  display: flex;
}
.value {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--color-text-primary);
}

.rootOutline > * {
  color: white !important;
}
