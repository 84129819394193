.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-background-overlay);
  backdrop-filter: blur(2px);
  cursor: pointer;
}
.enter {
  opacity: 0;
}
.enterActive {
  transition: opacity var(--animation-delay) ease-out;
  opacity: 1;
}
.exit {
  opacity: 1;
}
.exitActive {
  opacity: 0;
  transition: opacity var(--animation-delay) ease-out;
}
