.spinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  background: var(--color-background-primary);
  height: 100%;
  opacity: 0.5;
  top: 0;
  left: 0;
  border-radius: 24px 24px 0 0;
}
.root {
  width: 100%;
  height: 85svh;
  overflow: scroll;
  -ms-overflow-style: none;
}
.root::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.dialogHeader {
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 16px;
}
.bottomSheetHeader {
  padding: 16px 16px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.side {
  display: flex;
  align-items: center;
  gap: 16px;
}
.iconButton {
  background-image: var(--img-ic-24-chevron-down);
}
@media (min-width: 500px) {
  .spinnerContainer {
    border-radius: 12px;
  }
  .root {
    width: 368px !important;
    height: 628px;
    max-height: 85svh;
  }
}
