p,
li {
  font-family: var(--text-1-font-family) !important;
  font-size: var(--text-1-font-size) !important;
  font-style: var(--text-1-font-style) !important;
  font-weight: var(--text-1-font-weight) !important;
  line-height: var(--text-1-line-height) !important;
  letter-spacing: var(--text-1-letter-spacing) !important;
  text-align: var(--text-1-text-align);
  color: var(--color-text-primary);
}

.heading1 {
  font-family: var(--heading1-font-family) !important;
  font-size: var(--heading1-font-size) !important;
  font-style: var(--heading1-font-style) !important;
  font-weight: var(--heading1-font-weight) !important;
  line-height: var(--heading1-line-height) !important;
  text-align: var(--heading1-text-align);
}

.heading2 {
  font-family: var(--heading2-font-family) !important;
  font-size: var(--heading2-font-size) !important;
  font-style: var(--heading2-font-style) !important;
  font-weight: var(--heading2-font-weight) !important;
  line-height: var(--heading2-line-height) !important;
  text-align: var(--heading2-text-align);
}

.headline {
  font-family: var(--headline-font-family) !important;
  font-size: var(--headline-font-size) !important;
  font-style: var(--headline-font-style) !important;
  font-weight: var(--headline-font-weight) !important;
  line-height: var(--headline-line-height) !important;
  letter-spacing: var(--headline-letter-spacing) !important;
  text-align: var(--headline-text-align);
}

.title {
  font-family: var(--title-bold-font-family) !important;
  font-style: var(--title-bold-font-style) !important;
  font-size: var(--title-bold-font-size) !important;
  font-weight: var(--title-bold-font-weight) !important;
  line-height: var(--title-bold-line-height) !important;
  letter-spacing: var(--title-bold-letter-spacing) !important;
  text-align: var(--title-bold-text-align);
}

.subhead {
  font-family: var(--subhead-font-family) !important;
  font-size: var(--subhead-font-size) !important;
  font-style: var(--subhead-font-style) !important;
  font-weight: var(--subhead-font-weight) !important;
  line-height: var(--subhead-line-height) !important;
  letter-spacing: var(--subhead-letter-spacing) !important;
  text-align: var(--subhead-text-align);
}

.text-1 {
  font-family: var(--text-1-font-family) !important;
  font-size: var(--text-1-font-size) !important;
  font-style: var(--text-1-font-style) !important;
  font-weight: var(--text-1-font-weight) !important;
  line-height: var(--text-1-line-height) !important;
  letter-spacing: var(--text-1-letter-spacing) !important;
  text-align: var(--text-1-text-align);
}
.text-2 {
  font-family: var(--text-2-font-family) !important;
  font-size: var(--text-2-font-size) !important;
  font-style: var(--text-2-font-style) !important;
  font-weight: var(--text-2-font-weight) !important;
  line-height: var(--text-2-line-height) !important;
  letter-spacing: var(--text-2-letter-spacing) !important;
  text-align: var(--text-2-text-align);
}

.caption-1 {
  font-family: var(--caption-1-font-family) !important;
  font-size: var(--caption-1-font-size) !important;
  font-style: var(--caption-1-font-style) !important;
  font-weight: var(--caption-1-font-weight) !important;
  line-height: var(--caption-1-line-height) !important;
  letter-spacing: var(--caption-1-letter-spacing) !important;
  text-align: var(--caption-1-text-align);
}
.caption-2 {
  font-family: var(--caption-2-font-family) !important;
  font-size: var(--caption-2-font-size) !important;
  font-style: var(--caption-2-font-style) !important;
  font-weight: var(--caption-2-font-weight) !important;
  line-height: var(--caption-2-line-height) !important;
  letter-spacing: var(--caption-2-letter-spacing) !important;
  text-align: var(--caption-2-text-align);
}

a {
  font-family: var(--text-1-font-family) !important;
  font-size: var(--text-1-font-size) !important;
  font-style: var(--text-1-font-style) !important;
  font-weight: var(--text-1-font-weight) !important;
  line-height: var(--text-1-line-height) !important;
  letter-spacing: var(--text-1-letter-spacing) !important;
  text-align: var(--text-1-text-align);
  color: var(--color-text-primary);
}

button {
  font-family: var(--headline-font-family) !important;
  font-size: var(--headline-font-size) !important;
  font-style: var(--headline-font-style) !important;
  font-weight: var(--headline-font-weight) !important;
  line-height: var(--headline-line-height) !important;
  letter-spacing: var(--headline-letter-spacing) !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: var(--title-bold-font-family) !important;
  font-size: var(--title-bold-font-size) !important;
  font-style: var(--title-bold-font-style) !important;
  font-weight: var(--title-bold-font-weight) !important;
  line-height: var(--title-bold-line-height) !important;
  letter-spacing: var(--title-bold-letter-spacing) !important;
  text-align: var(--title-bold-text-align);
}

.icon-16 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 16px;
  height: 16px;
}

.icon-18 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 18px;
  height: 18px;
}

.icon-24 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 24px;
  height: 24px;
}

.icon-28 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 28px;
  height: 28px;
}

.icon-48 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 48px;
  height: 48px;
}

.icon-menu {
  background-image: var(--img-ic-24-menu) !important;
}

.button-icon-24 {
  border: 0;
  background: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  border-radius: 50%;
}

.closeButton-mobile {
  border: 0;
  background: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  border-radius: 50%;
  background-image: var(--img-ic-24-close) !important;
}
.closeButton-desktop {
  border: 0;
  background: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  border-radius: 50%;
  filter: invert(1);
  background-image: var(--img-ic-24-close) !important;
}
.button-chevronDown {
  border: 0;
  background: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  border-radius: 50%;
  background-image: var(--img-ic-24-chevron-down) !important;
}
.button-chevronRight {
  border: 0;
  background: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  border-radius: 50%;
  background-image: var(--img-ic-24-chevron-right) !important;
}
.button-arrowBack {
  border: 0;
  background: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  border-radius: 50%;
  background-image: var(--img-ic-24-arrow-back) !important;
}
