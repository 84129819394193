.root {
  border-radius: 12px;
  background: var(--color-button-secondary-default);
  cursor: pointer;
}
.container {
  padding: 8px 16px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.content {
  padding: 12px 24px 0 0;
  padding-top: 12px;
  padding-right: 24px;
}
.chevronButton {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate(0, -50%);
}
.floatingLabel {
  position: absolute;
  top: 8px;
  left: 16px;
  color: var(--color-text-secondary);
}
.input {
  pointer-events: none;
  border: none;
  background: none;
  padding: 0;
}

.dialog {
  width: 100%;
}
.message {
  padding: 0 16px;
}
.submit {
  padding: 16px;
}
@media (min-width: 500px) {
  .dialog {
    width: 368px;
  }
  .message {
    padding: 0 20px;
  }
  .submit {
    padding: 16px 20px 20px;
  }
}
