.root {
  width: 112px;
  border-radius: 4px;
  background: var(--color-background-primary);
  box-shadow: var(--elevation-4-dp);
  cursor: default;
}
.image {
  width: 112px;
  height: 96px;
  object-fit: contain;
  object-position: center;
}
.data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
}
.name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  display: -webkit-box;
  line-clamp: 3;
  box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.data p {
  max-width: 70px;
  text-align: center;
}
