.card {
  box-sizing: border-box;
  width: 160px;
  height: 104px;
  padding: 10px;
  position: relative;
  isolation: isolate;
}

.card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  border-radius: 16px;
  border: 2px solid var(--SegmentedControl-Background, #ebebf0);
}

.typeIcon {
  height: 24px;
  width: auto;
  object-fit: contain;
  object-position: center;
  pointer-events: none;
}

.mask {
  margin-top: 36px;
  pointer-events: none;
}
