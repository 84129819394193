.container {
  width: 100%;
  height: 85svh;
  overflow: scroll;
  -ms-overflow-style: none;
  border-radius: 20px 20px 0 0;
}
.container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.header {
  padding: 16px 16px 24px;
  display: flex;
  align-items: center;
  position: relative;
}
.buttonIcon {
  width: 24px;
  height: 24px;
}
.arrowButton {
  margin-right: 16px;
}
.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
}
.image {
  height: 368px;
  width: 100%;
  object-fit: cover;
  user-select: none;
}
.data {
  padding: 20px 16px 16px;
}
.endAt {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.description {
  margin-top: 12px;
  white-space: pre-line;
}

@media (min-width: 500px) {
  .container {
    width: 368px;
    max-height: 616px;
    border-radius: 12px;
  }
  .data {
    padding: 20px;
  }
}
