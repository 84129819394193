.root {
  padding: 20px;
  box-sizing: border-box;
}

.container {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-button-secondary-default);
  animation: shine 1.8s infinite linear;
}

.placeholder {
  animation: shine 1.8s infinite linear;
  pointer-events: none;
  object-fit: cover;
  object-position: center;
}

@keyframes shine {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
