.paymentType {
  width: 124px;
  height: 80px;
  padding: 8px;
  box-sizing: border-box;
  position: relative;
  isolation: isolate;
  background: var(--color-background-primary);
  border-radius: 12px;
  cursor: pointer;
}

.paymentType::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
}

/* TODO: цвета */
.paymentType[is-active='false']::before {
  border: 1px solid var(--color-field-inactive, #dfdfe5);
}
.paymentType[is-active='true']::before {
  border: 1px solid var(--Icon-Primary, #f32b2b);
  box-shadow: var(--elevation-4-dp);
}

.icon {
  pointer-events: none;
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.label {
  position: absolute;
  width: calc(124px - 16px);
  left: 8px;
  bottom: 8px;
  pointer-events: none;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.radioContainer {
  position: absolute;
  top: 8px;
  right: 8px;
  pointer-events: none;
}
