.root {
  position: relative;
  display: flex;
  flex-direction: column;
}
.imageContainer {
  height: fit-content;
}
.image {
  overflow: hidden;
  border-radius: 12px 12px 0 0;
  width: 100%;
  height: 100px;
  object-fit: contain;
  outline: none;
}
.dataContainer {
  padding: 0 6px 6px;
  min-height: 86px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 4px;
}
.name {
  max-width: 100%;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  display: -webkit-box;
  line-clamp: 2;
  box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.description,
.size {
  color: var(--color-text-secondary);
}

.description {
  word-break: break-word;
}
.nutritionFactsButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-image: var(--img-ic-24-nutrition-facts);
  z-index: 2;
}

.nutritionFacts__container {
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  overflow: scroll;
  z-index: 1;
  background: var(--color-background-info);
  border-radius: 12px;
  -ms-overflow-style: none;
}
.nutritionFacts__container::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.nutritionFacts__content {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.nutritionFacts__title,
.nutritionFacts {
  color: var(--color-text-info);
  word-break: break-word;
}
