.root {
  padding: 0 20px;
  cursor: pointer;
}
.container {
  border-bottom: 1px solid var(--color-divider-divider);
}
.root:last-child .container {
  border-bottom: none;
}
.content {
  padding: 8px 0 11px;
  display: flex;
}
.image {
  width: 96px;
  height: 124px;
  object-fit: cover;
  object-position: center;
  border-radius: 12px;
  user-select: none;
}
.data {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  display: -webkit-box;
  line-clamp: 3;
  box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.endAt {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media (min-width: 500px) {
  .image {
    width: 150px;
    height: 194px;
  }
}

.loader {
  background-color: var(--color-button-secondary-default);
  border-radius: 12px;
}
.processingImage {
  width: 72px;
  height: 72px;
  animation: shine 1.8s infinite linear;
  user-select: none;
}

@keyframes shine {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
