.root {
  display: flex;
  flex-direction: column;
}
.content {
  padding: 16px 0 0;
}
.title {
  display: flex;
  align-items: center;
  padding: 0 0 16px;
}
.slider {
  padding: 26px 0 0;
}
