.root {
  width: 100%;
}

.content {
  padding: 8px 16px 16px;
}

.data {
  display: flex;
  flex-direction: column;
}

.submit {
  padding-top: 12px;
}

.messageContainer {
  padding: 8px 0 0 12px;
}

.message {
  color: var(--color-text-secondary);
}
.messageFailed {
  color: var(--color-text-error);
}
