.root {
  width: 280px;
  height: 172px;
  background: var(--color-background-order);
  border-radius: 12px;
}
.container {
  padding: 16px;
}
.info {
  margin-top: 12px;
  display: flex;
  align-items: flex-start;
}
.timeContainer {
  position: relative;
  height: 36px;
  width: 100%;
}
.timeCaption {
  color: var(--color-text-secondary);
  max-width: 115px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.timeEl {
  top: 12px;
  /*overflow: hidden;*/
  /*text-overflow: ellipsis;*/
  /*white-space: nowrap;*/
}

.statusLabel {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.mobileNumber {
  font-family: Inter;
  font-size: 12px !important;
  font-weight: 400!important;
  color: #6B6B6D;

}

.galery {
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.galleryCard {
  width: 56px;
  height: 56px;
  overflow: hidden;
  background: var(--color-background-primary);
  border-radius: 8px;
  box-shadow: var(--elevation-2-dp);
}
.galleryImage {
  width: 56px;
  height: 56px;
  object-position: center;
  object-fit: cover;
}
.hiddenCard {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-background-primary);
  border-radius: 8px;
  width: 56px;
  height: 56px;
  box-shadow: var(--elevation-2-dp);
}
.hiddenValue {
  color: var(--color-text-secondary);
}

.mobileHeaderContainer {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 600px) {
  .root {
    /* width: 272px;
    height: 180px; */
    width: 362px !important;
    height: 240px !important;
  }
  .container {
    padding: 16px;
  }
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .orderNumber {
    color: var(--color-text-secondary);
  }
  .timeContainer {
    margin-top: 38px;
    height: 48px;
    width: 100%;
  }
  .timeCaption {
    max-width: 100%;
  }
  .timeEl {
    max-width: 100%;
    top: 24px;
  }
  .galery {
    margin-top: 24px;
    gap: 16.27px;
  }
  .galleryImage {
    width: 70px;
    height: 70px;
  }
  .galleryCard {
    width: 70px;
    height: 70px;
    box-shadow: none;
  }


  .hiddenCard {
    width: 70px;
    height: 70px;
    box-shadow: none;
  }
}
