.categories {
  position: sticky;
  top: calc(84px + 43px);

  margin-left: auto;
  background: var(--color-background-primary);
  z-index: 2;
  height: 100%;
  width: 228px;
}
.dividerContainer {
  border-bottom: none;
  transition: border-bottom var(--animation-delay) linear;
}
/*.dividerContainer__crossing {*/
/*  border-bottom: 1px solid var(--color-divider-divider);*/
/*}*/
.categoriesContent {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}
.categoriesList {
  box-sizing: border-box;
  padding: 6px 0;
  display: flex;
  width: 100%;
  overflow: scroll;
  z-index: 1 !important;
  max-height: calc(100dvh - 104px);
}
.categoriesList::-webkit-scrollbar {
  width: 0;
}


.cart {
  padding-right: 16px;
}
.cartButton {
  position: relative;
  min-width: 140px;
  width: fit-content;
}
.shadowBlock {
  z-index: 1;
  position: absolute;
  top: 0;
  left: -90px;
  width: 90px;
  height: 48px;
  pointer-events: none;
  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
@media (min-width: 600px) {
  .categories {
  }
  .categoriesList {
    
  }
  .categoriesContainer {
    padding-top: 16px;
    padding-bottom: 12px;
  }
  .cart {
    padding-right: 42px;
  }
  
}

@media (min-width: 1440px) {
  .cart {
    padding-right: calc((100vw - 1280px) / 2);
  }
  
  
  
}
