.root {
  padding: 0 20px;
}
.container {
  border-bottom: 1px solid var(--color-divider-divider);
}
.root:last-child > .container {
  border-bottom: none;
}
.content {
  padding: 12px 0 11px;
}
.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.title {
  color: var(--color-text-secondary);
}
.changeButton {
  background: none;
  padding: 0;
  cursor: pointer;
  border: none;
  color: var(--color-text-tertiary);
}
.data {
  position: relative;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.name {
  padding-right: 83px;
}
.quantityContainer {
  position: absolute;
  right: 0;
  top: 16px;
}
