.root {
  width: 433px;
  height: 500px;
  border-radius: 20px;
  box-shadow: var(--elevation-8-dp);
  background: var(--color-background-primary);
  position: relative;
}
.searchField {
  padding: 20px 20px 0px;
}
.errorConntainer {
  padding: 8px 20px 0;
}
.reload {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}
.placeholderContainer {
  padding-top: 110px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.restaurantsList {
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(500px - 72px);
  overflow: scroll;
  -ms-overflow-style: none;
}
.restaurantsList::-webkit-scrollbar {
  width: 0;
  height: 0;
}
