.root {
  min-height: calc(100vh - 118px - 32px);
  width: 100%;
}
.breadCrumbs {
  padding: 20px 16px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  max-width: 1280px;
}
.breadCrumbs > span {
  color: var(--color-text-secondary);
}
.title {
  padding: 0 16px;
  max-width: 1280px;
}

.contacts {
  margin: 0 auto;
  max-width: 1280px;
  margin-top: 36px;
  margin-bottom: 40px;
  padding: 0 16px;
}
.contactsList {
  display: grid;
  grid-template-columns: repeat(1, 100%);
  row-gap: 24px;
}
.contactsListItem,
.legalInfoListItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 460px;
}

.map {
  height: 600px;
  width: 100%;
}

.legalInfo {
  margin: 0 auto;
  max-width: 1280px;
  margin-top: 40px;
  margin-bottom: 60px;
  padding: 0 16px;
}
.legalInfoList {
  margin-top: 36px;
  display: grid;
  grid-template-columns: repeat(1, 100%);
  row-gap: 24px;
}

.textMargin {
  margin-top: 8px;
}

.blockMargin {
  margin-top: 16px;
}

.copyBlock {
  display: flex;
  align-items: center;
  gap: 12px;
}

.link {
  color: var(--color-text-tertiary);
  text-decoration: none;
}

.secondaryText {
  color: var(--color-text-secondary);
}

.vacanciesButton {
  width: 184px;
}
.socialNetworksList {
  display: flex;
  align-items: center;
  gap: 12px;
}
.socialNetworkButton {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 500px) {
  .breadCrumbs {
    padding: 20px;
  }
  .title {
    padding: 0 20px;
  }
  .contacts {
    padding: 0 20px;
  }
  .legalInfo {
    padding: 0 20px;
  }
}

@media (min-width: 600px) {
  .root {
    padding-top: calc(84px + 32px);
    min-height: calc(100vh - 84px - 32px);
  }
}

@media (min-width: 1024px) {
  .breadCrumbs {
    padding: 20px 42px;
    margin: 0 auto;
  }
  .title {
    margin: 0 auto;
    padding: 0 42px;
  }
  .contacts {
    margin-top: 40px;
    margin-bottom: 60px;
    padding: 0 42px;
  }
  .contactsList {
    grid-template-columns: repeat(2, calc(50% - 40px));
    row-gap: 40px;
    column-gap: 40px;
  }
  .legalInfo {
    margin-top: 120px;
    padding: 0 42px;
  }
  .legalInfoList {
    margin-top: 69px;
    grid-template-columns: repeat(2, calc(50% - 40px));
    row-gap: 40px;
    column-gap: 40px;
  }
}

@media (min-width: 1440px) {
  .legalInfo {
    margin-top: 80px;
  }
  .contacts {
    margin-bottom: 80px;
  }
}
