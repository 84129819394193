.root {
  position: absolute;
  right: 12px;
  z-index: 100;
  padding: 8px;
  top: 6px;
}
.openButton {
  border: none;
  appearance: none;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: none;
}
.profileContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  padding: 4px 8px 4px 4px;
  border-radius: 100px;
  column-gap: 4px;
}
.profileImg {
  height: 24px;
  width: 24px;
  fill: white !important;
}
.cart {
  padding-right: 16px;
}
.cartButton {
  position: relative;
  min-width: 140px;
  width: fit-content;
}
.openButton__mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bonusLabel {
  padding: 6px 12px;
  min-width: 42px;
  box-sizing: border-box;
  color: white;
  background: var(--color-button-primary-default);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.09px;
}

.closeButton {
}

@media (max-width: 1024px) {

  .addressContainer {
    max-width: fit-content;
    width: 100%;
  }
  .balanceContainer {
    padding-left: 0;
  }
  .header {
    padding: 20px 42px 0;
  }
  .container {
    border-bottom: 1px solid var(--color-divider-divider);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1196px;
    margin: 0 auto;
    padding-bottom: 13px;
  }
  .appData {
    display: flex;
    align-items: center;
    gap: 20px;
    max-width: 60%;
  }
  .userData {
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
  }
}