.fields {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc(85svh - 60px - 60px - 60px - 88px);
  padding: 0 16px 88px;
  overflow: scroll;
  -ms-overflow-style: none;
}
.fields::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.homeType {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
}
.submit {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
  background-color: var(--color-background-primary);
}
.homeValues {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}
.homeValueContainer {
  width: calc(100% / 2 - 4px);
}
.conditionsContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}
.conditionsContainer button {
  width: fit-content;
}
@media (min-width: 500px) {
  .fields {
    padding: 0 20px 92px;
    height: calc(600px - 60px - 60px - 60px - 92px);
    max-height: calc(85svh - 60px - 60px - 60px - 92px);
  }
  .submit {
    padding: 20px;
    border-radius: 0 0 12px 12px;
  }
}
