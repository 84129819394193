.root {
  border-radius: 12px;
  background: var(--color-background-primary);
  box-shadow: var(--elevation-4-dp);
  padding: 2px;
  cursor: pointer;
}
.active {
  border: 2px solid var(--color-button-primary-default);
  padding: 0;
}
.disabled {
  opacity: 0.5;
}
