.root {
  width: 100%;
  border-radius: 12px;
  cursor: pointer;
  overflow: hidden;
  background: var(--color-button-secondary-default);
}
.content {
  display: flex;
  gap: 16px;
  padding: 14px 16px;
  position: relative;
}
.chevronButton {
  position: absolute;
  padding: 0;
  right: 16px;
  top: 14px;
}
.changeButton__container {
  position: relative;
}
.changeButton {
  cursor: pointer;
  position: absolute;
  border: none;
  background: none;
  top: calc(-8px - 24px);
  right: 0;
  color: var(--color-text-tertiary);
}
.modifiers {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 16px;
}

@media (min-width: 500px) {
  .root {
    transition: height var(--animation-delay) linear;
    background: var(--color-background-primary);
    border: 1px solid var(--color-field-inactive);
  }
  .root__open {
    height: 100%;
    border: none;
    box-shadow: var(--elevation-4-dp);
  }
  .header {
    box-sizing: border-box;
    width: 100%;
    padding: 13px 15px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .header__open {
    padding: 14px 16px;
  }
  .header__open .chevronButton {
    top: 12px;
    right: 15px;
  }
  .chevronButton {
    right: 16px;
    top: 11px;
  }
  .name {
    padding-right: 24px;
  }
}
