.root {
  position: relative;
  width: 100%;
  height: 85svh;
  max-height: 600px;
  overflow: scroll;
}
.container {
  padding-top: 20px;
  padding-bottom: 64px;
  display: flex;
  flex-direction: column;
}
.imageContainer {
  height: 320px;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
}
.image {
  height: 320px;
  object-fit: cover;
  object-position: center;
  width: 100%;
}
.successImage {
  object-fit: contain;
}
.productDataContainer {
  width: 100%;
}
.header {
  padding: 16px 16px 12px;
}
.headerContent {
  position: relative;
}
.name {
  padding-right: 24px;
}
.nutritionFactsButton {
  background-image: var(--img-ic-24-nutrition-facts);
  position: absolute;
  right: 0;
  top: 2px;
}
.nutrritionFactsContainer {
  position: absolute;
  border-radius: 16px;
  background: var(--color-background-info);
  top: 38px;
  z-index: 1;
  width: 100%;
}
.nutrritionFactsContent {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.nutritionFactsTitle {
  color: var(--color-text-info);
}
.nutritionFact {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nutritionFact p {
  color: var(--color-text-info);
}
.description {
  padding: 0 16px 8px;
}
.description p {
  white-space: pre-wrap;
}
.size {
  padding: 0 16px 20px;
}
.size p {
  color: var(--color-text-secondary);
}
.modifier {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 16px 24px;
}
.submitContainer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 1px solid var(--color-divider-divider);
  background: var(--color-background-primary);
}
.submitContent {
  padding: 7px 16px 8px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.submitButtonContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.submitButtonContent,
.submitButtonContent p {
  font: inherit;
}
.submitButtonContent p {
  color: var(--color-text-on-color);
}

@media (min-width: 700px) {
  .root {
    overflow: hidden;
    position: static;
  }
  .container {
    flex-direction: row;
    padding: 0;
  }
  .imageContainer {
    padding: 0;
    height: auto;
    width: 50%;
  }
  .productDataContainer {
    width: 50%;
    position: relative;
  }
  .productData {
    padding-bottom: 85px;
  }
  .productDataContent {
    height: calc(85svh - 85px);
    /* height: calc(85svh - 56px - 85px); */
    max-height: calc(600px - 85px);
    /* max-height: calc(600px - 56px - 85px); */
    overflow: scroll;
  }
  .submitContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: none;
    border-radius: 0 0 12px 0;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .submitContent {
    border-top: 1px solid var(--color-divider-divider);
    gap: 20px;
    padding: 20px 0;
    box-sizing: border-box;
  }
}

@media (min-width: 1024px) {
  /* .root {
    overflow: hidden;
    position: static;
  }
  .container {
    flex-direction: row;
    padding: 0;
  }
  .imageContainer {
    padding: 0;
    height: auto;
    width: 50%;
  }
  .productDataContainer {
    width: 50%;
    position: relative;
  }
  .productData {
    padding-bottom: 88px;
  }
  .productDataContent {
    max-height: calc(85svh - 56px - 88px);
    overflow: scroll;
  }
  .submitContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: none;
  }
  .submitContent {
    gap: 20px;
    padding: 20px;
  } */
  .root {
    width: 856px;
  }
  .header {
    padding: 20px 20px 12px;
  }
  .productDataContent {
    height: calc(600px - 88px);
    max-height: calc(85svh - 88px);
    /* height: calc(600px - 56px - 88px);
    max-height: calc(85svh - 56px - 88px); */
  }
  .description {
    padding: 0 20px 8px;
  }
  .size {
    padding: 0 20px 20px;
  }
  .modifier {
    padding: 0 20px 20px;
  }
}
