:root {
  /* - https://www.figma.com/file/rgWWWBvC68uKhbV0RWeHIH/FEat-UI-Base-Library?node-id=0%3A1 - */

  /**
     ----------- TYPOGRAPHY -----------
     **/

  /* heading1 */
  --heading1-font-family: inter;
  --heading1-font-size: 32px;
  --heading1-font-style: normal;
  --heading1-font-weight: 700;
  --heading1-line-height: 38px;
  --heading1-text-align: left;

  /* heading2 */
  --heading2-font-family: inter;
  --heading2-font-size: 24px;
  --heading2-font-style: normal;
  --heading2-font-weight: 700;
  --heading2-line-height: 38px;
  --heading2-text-align: left;

  /* title bold */
  --title-bold-font-family: inter;
  --title-bold-font-size: 20px;
  --title-bold-font-style: normal;
  --title-bold-font-weight: 700;
  --title-bold-line-height: 28px;
  --title-bold-letter-spacing: -0.33000001311302185px;
  --title-bold-text-align: left;

  /* Headline */
  --headline-font-family: inter;
  --headline-font-size: 16px;
  --headline-font-style: normal;
  --headline-font-weight: 600;
  --headline-line-height: 24px;
  --headline-letter-spacing: -0.18000000715255737px;
  --headline-text-align: left;

  /* subhead */
  --subhead-font-family: inter;
  --subhead-font-size: 14px;
  --subhead-font-style: normal;
  --subhead-font-weight: 600;
  --subhead-line-height: 20px;
  --subhead-letter-spacing: -0.09000000357627869px;
  --subhead-text-align: left;

  /* text-1- */
  --text-1-font-family: inter;
  --text-1-font-size: 16px;
  --text-1-font-style: normal;
  --text-1-font-weight: 400;
  --text-1-line-height: 24px;
  --text-1-letter-spacing: -0.18000000715255737px;
  --text-1-text-align: left;

  /* text-2 */
  --text-2-font-family: inter;
  --text-2-font-size: 14px;
  --text-2-font-style: normal;
  --text-2-font-weight: 400;
  --text-2-line-height: 20px;
  --text-2-letter-spacing: -0.09000000357627869px;
  --text-2-text-align: left;

  /* caption-1 */
  --caption-1-font-family: inter;
  --caption-1-font-size: 12px;
  --caption-1-font-style: normal;
  --caption-1-font-weight: 400;
  --caption-1-line-height: 16px;
  --caption-1-letter-spacing: 0.009999999776482582px;
  --caption-1-text-align: left;

  /* caption-2 */
  --caption-2-font-family: inter;
  --caption-2-font-size: 12px;
  --caption-2-font-style: normal;
  --caption-2-font-weight: 600;
  --caption-2-line-height: 16px;
  --caption-2-letter-spacing: 0.009999999776482582px;
  --caption-2-text-align: left;

  /*------------- ELEVATIONS -------------*/
  --elevation-2-dp: 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  --elevation-4-dp: 0px 0px 2px rgba(0, 0, 0, 0.08),
    0px 4px 6px rgba(0, 0, 0, 0.16);
  --elevation-6-dp: 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 6px 8px rgba(0, 0, 0, 0.16);
  --elevation-8-dp: 0px 0px 4px rgba(0, 0, 0, 0.08),
    0px 8px 12px rgba(0, 0, 0, 0.12);
  --elevation-16-dp: 0px 0px 8px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.1);

  /*------------- SIZES -------------*/
  --size-mobile: 500px;
  --size-desktop: 1024px;

  /*------------- DELAYS -------------*/
  --animation-delay: 200ms;
}
