.root {
  min-height: calc(100vh - 118px - 32px);
  width: 100%;
}
.loaderContainer {
  height: calc(100vh - 118px - 32px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.breadCrumbs {
  padding: 20px 16px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.breadCrumbs > span {
  color: var(--color-text-secondary);
}
.title {
  padding: 0 16px;
}
.mapContainer {
  padding-top: 36px;
  position: relative;
}
.map {
  width: 100%;
  /* max-height: calc(100vh - 118px - 36px - 28px - 60px);*/
  height: 500px;
  /* height: calc(100svh - 118px); */
}

.paymentTypes {
  padding: 32px 16px 16px;
}
.paymentTypesContainer {
  border-radius: 20px;
  /* TODO: поменять цвет на либовский*/
  background: #fafafa;
  width: 100%;
}
.paymentTypesTitle {
  padding: 16px;
}
.paymentTypesList {
  padding: 0 16px 16px;
}
.paymentTypesListItems {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.paymentTypesListItem {
  padding-bottom: 20px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
}
.paymentTypesListItemImage {
  padding: 12px;
  border-radius: 12px;
  background: var(--color-background-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}
.paymentTypesListItemCaption {
  color: var(--color-text-secondary);
  margin-top: 4px;
}
.link {
  text-decoration: none;
  color: var(--color-text-tertiary);
}
.makeOrder {
  width: 212px !important;
}

@media (min-width: 500px) {
  .breadCrumbs {
    padding: 20px;
  }
  .title {
    padding: 0 20px;
  }
}

@media (min-width: 600px) {
  .root {
    padding-top: calc(84px + 32px);
    min-height: calc(100vh - 84px - 32px);
  }
  .loaderContainer {
    height: calc(100vh - 84px - 32px);
  }
}

@media (min-width: 1024px) {
  .breadCrumbs {
    padding: 20px 0;
    max-width: 940px;
    margin: 0 auto;
  }
  .title {
    max-width: 940px;
    margin: 0 auto;
    padding: 0;
  }
  .map {
    height: 664px;
    max-height: 664px;
  }

  .paymentTypes {
    padding: 80px 42px 140px;
  }
  .paymentTypesContainer {
    position: relative;
    min-height: 600px;
  }
  .paymentTypesTitle {
    padding: 40px;
  }
  .paymentTypesList {
    padding: 20px 396px 40px 40px;
  }
  .paymentTypesListItems {
    display: grid;
    grid-template-columns: repeat(2, 224px);
    row-gap: 40px;
    column-gap: 40px;
    padding-bottom: 40px;
  }
  .paymentTypesListItem {
    padding: 0;
  }
  .phoneImageContainer {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .phoneImage {
    height: 549px;
    object-fit: cover;
    object-position: center;
  }
}

@media (min-width: 1440px) {
  .paymentTypesContainer {
    max-width: 1280px;
    margin: 0 auto;
  }
  .breadCrumbs {
    max-width: 1280px;
  }
  .title {
    max-width: 1280px;
  }
}
