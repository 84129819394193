
/*.banners::after,*/
/*.banners::before {*/
/*  height: 172px;*/
/*  width: 16px;*/
/*  bottom: 0;*/
/*  content: '';*/
/*  position: absolute;*/
/*  z-index: 1;*/
/*}*/
.banners::after {
  right: 0;
  background: linear-gradient(270deg, #ffffff 50%, rgba(255, 255, 255, 0) 100%);
}
.banners::before {
  left: 0;
  background: linear-gradient(90deg, #ffffff 50%, rgba(255, 255, 255, 0) 100%);
}
.list {
  display: flex;
  gap: 20px;
  max-width: 100%;
  flex-wrap: nowrap;
  z-index: 0 !important;
  height: 100%;
}
.list > .swiper-button-prev,
.list > .swiper-button-next {
  background-color: var(--color-background-primary) !important;
  width: 48px !important;
  height: 48px !important;
  /*border-radius: 24px !important;*/
  box-shadow: var(--elevation-4-dp) !important;
}
.banner {
  box-sizing: border-box;
  flex-shrink: 0;
  cursor: pointer;
  overflow: hidden;
  /*border-radius: 12px;*/
}
.banner__story {
  /*width: 140px !important;*/
  /*height: 172px !important;*/
  /*border-radius: 12px;*/
  background: var(--color-button-secondary-default);
}
.banner__lastOrder {
  /*width: 100% !important;*/
  /*height: 172px !important;*/
}
.banner__giftRepurchase {
  /*width: 280px !important;*/
  /*height: 172px !important;*/
}
/*.banner:first-child {*/
/*  margin-left: 16px !important;*/
/*}*/
/*.banner:last-child {*/
/*  margin-right: 16px !important;*/
/*}*/

@media (max-width: 500px) {
  .banners {
    position: sticky;
    /*top: -322px;*/
    z-index: 9;
    height: 100%;
    /*margin-top: -64px;*/
    left: 0;
    right: 0;
  }
}

@media (min-width: 500px) {
  .banners {
    padding: calc(84px + 4px + 32px) 0 0;
  }
  .banners::after,
  .banners::before {
    height: 240px;
    width: 42px;
  }
  .banner__lastOrder {
    width: 362px !important;
    height: 240px !important;
  }
  .banner__giftRepurchase {
    width: 362px !important;
    height: 240px !important;
  }
  .banner__story {
    width: 184px !important;
    height: 240px !important;
  }
  .banner:first-child {
    margin-left: 42px !important;
  }
  .banner:last-child {
    margin-right: 42px !important;
  }
}

@media (min-width: 500px) and (max-width: 1024px) {


}

@media (min-width: 500px) and (max-width: 1024px) {
  .banners {
    height: 100%;
    padding: 12px 0 6px;
  }
  .banner:first-child {
    margin-left: 16px !important;
  }

  .banner__story {
    border-radius: 12px;
  }

  .list {
    height: 100%;
  }

  .banners::after,
  .banners::before {
    display: none;
  }

}

@media (min-width: 1440px) {
  .list {
    max-width: calc(1280px + 42px * 2);
  }
  .banners::after {
    right: calc((100% - 1280px) / 2 - 42px);
  }
  .banners::before {
    left: calc((100% - 1280px) / 2 - 42px);
  }
}
