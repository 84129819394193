.root {
  position: relative;
  cursor: pointer;
  height: 380px;
  /*width: 100%;*/
  /* TODO: найти правильный цвет и проработать плейсхолдер*/
  /* background: var(--color-button-secondary-default); */
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 2px 20px 0px #9797971A;
  transition: all 0.3s ease-out;
}

.root:hover {
  transform: scale3d(1.025, 1.025, 1.025);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.cardDisabled {
  opacity: 0.7;
}

.image {
  width: 100%;
  min-height: 228px;
  object-fit: contain;
  object-position: center;
  border-radius: 12px 12px 0 0;
}
.imageError {
  object-fit: fill;
}
.image__unavailable {
  opacity: 0.4;
}
.unavailableContainer {
  position: absolute;
  bottom: 8px;
  left: 8px;
  padding: 8px 12px;
  border-radius: 10px;
  background: var(--color-background-primary);
}

.data {
  padding: 8px;
  min-height: 64px;
}

.data > p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  display: -webkit-box;
  line-clamp: 2;
  box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.constructorName {

}

.submit {
  padding: 0 12px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  margin-top: 12px;
}

.buttonPrice {
  position: relative;
  font-size: 16px !important;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.18000000715255737px;
  text-align: center;
  height: 48px;
}

@media (min-width: 600px) {
  .root {
    /*height: 220px;*/
  }
  .image {
    height: 220px;
  }
}
