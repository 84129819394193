.root {
  display: flex;
  flex-direction: column;
}
.data {
  padding: 36px 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.data > p {
  text-align: center;
}
.address {
  color: var(--color-text-secondary);
}
.submit {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
