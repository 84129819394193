.root {
  cursor: pointer;
  position: relative;
  background: transparent;
  padding: 8px 12px 8px 12px;
  display: block;
  border: none;
  text-align: center;
  border-radius: 100px;
  overflow: hidden;
  white-space: nowrap;
  font-family: var(--subhead-font-family) !important;
  font-size: var(--subhead-font-size) !important;
  font-style: var(--subhead-font-style) !important;
  font-weight: var(--subhead-font-weight) !important;
  line-height: var(--subhead-line-height) !important;
  letter-spacing: var(--subhead-letter-spacing) !important;
  text-align: var(--subhead-text-align) !important;
  color: var(--color-text-secondary);
}
.active {
  color: var(--color-text-on-color);
  background: var(--color-button-primary-default);
  width: 100%;
}

.root:disabled {
  color: var(--color-text-disabled);
}
.active:disabled {
  background: var(--color-tab-disabled);
}
