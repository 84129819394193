.bottomSheet {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
}

.container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--color-background-primary);
  border-radius: 24px 24px 0px 0px;
  box-shadow: var(--elevation-16-dp) !important;
  overflow: hidden;
}

.containerEnter {
  max-height: 0 !important;
}
.containerEnterActive {
  transition: max-height var(--animation-delay) linear;
  max-height: 95% !important;
}
.containerExit {
  max-height: 95% !important;
}
.containerExitActive {
  transition: max-height var(--animation-delay) ease-out;
  max-height: 0 !important;
}
