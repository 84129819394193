.root {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  -ms-overflow-style: none;
}
.root::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.placeholderContainer {
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}
