.header {
  display: flex;
  align-items: center;
}
.navigateButton {
  margin-left: auto;
}

.card {
  margin-top: 16px;
}
