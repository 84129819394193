.root {
  display: flex;
}

.container {
  position: absolute;
  top: 32px;
  left: 16px;
  border-radius: 20px;
  box-shadow: var(--elevation-4-dp);
  background: var(--color-background-primary);
  z-index: 12;
  width: 320px;
  user-select: none;
}
.content {
  padding-bottom: 8px;
}
.header {
  padding: 16px 16px 12px;
}

.languagePickerContainer {
}

.list {
  display: flex;
  flex-direction: column;
}
.listItem {
  padding: 0 0 0 16px;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
}
.listItemContent {
  padding: 12px 16px 11px 0;
  border-bottom: 1px solid var(--color-divider-divider);
  display: flex;
  align-items: center;
}
.listItem:last-child > .listItemContent {
  padding: 12px 16px 12px 0;
  border-bottom: none;
}

.flag {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}
.radio {
  margin-left: auto;
}

@media (min-width: 600px) {
  .container {
    left: 42px;
  }
}

.containerEnter {
  opacity: 0;
}
.containerEnterActive {
  transition: opacity var(--animation-delay) ease-out;
  opacity: 1;
}
.containerExit {
  opacity: 1;
}
.containerExitActive {
  opacity: 0;
  transition: opacity var(--animation-delay) ease-out;
}

@media (min-width: 1024px) {
  .container {
    position: fixed;
  }
}
