.container {
  position: relative;
  isolation: isolate;
  margin-bottom: 62px;
}

.title {
  color: var(--color-text-on-color);
  padding-right: 48px;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.balance {
  display: block;
  margin-top: 8px;
  color: var(--color-text-on-color);
  border-radius: 100px;
  background: linear-gradient(246deg, #321ff3, #4c6ee7 45%);
  text-align: center;
  text-overflow: ellipsis;
  padding: 4px 11.5px;
  min-width: 42px;
  width: fit-content;
  box-sizing: border-box;
}

.logo {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  object-fit: cover;
}

@media (min-width: 500px) {
  .container {
    margin-bottom: 54px;
  }
}
