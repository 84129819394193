.root {
  padding: 2px;
}
.radio {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;
  background-color: #ffffff;
  /* transition: border var(--animation-delay) linear; */

  border: 2px solid var(--color-text-disabled);
  border-radius: 50%;
}
.radio__active {
  border: 2px solid var(--color-button-primary-default);
}
.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;

  background-color: var(--color-button-primary-default);
  opacity: 0;
  /* transition: opacity var(--animation-delay) linear; */
}
.dot__active {
  opacity: 1;
}
