.root {
  width: 100%;
}
.info {
  color: var(--color-text-secondary);
  padding: 0 16px;
}
.submit {
  padding: 0 16px 16px;
}
.input {
  padding: 24px 16px 16px;
}

@media (min-width: 500px) {
  .root {
    width: 400px;
  }
  .input {
    padding: 24px 20px 16px;
  }
  .info {
    padding: 0 20px;
  }
  .submit {
    padding: 0 20px 20px;
  }
}
