.root {
  border-radius: 12px;
  box-shadow: 0px 2px 20px 0px #9797971A;
  cursor: pointer;
  transition: all 0.3s ease-out;
  position: relative;
}
.root:hover {
  transform: scale3d(1.025, 1.025, 1.025);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}
.image {
  width: 100%;
  height: 98px;
  object-fit: contain;
  object-position: center;
  border-radius: 12px 12px 0 0;
}
.videoContainer {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
}
.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.placeholder {
  object-fit: cover;
}
.data {
  padding: 8px;
  min-height: 64px;
}

.cardDisabled {
  opacity: 0.7;
}

.data > p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  display: -webkit-box;
  line-clamp: 2;
  box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.size {
  padding-top: 4px;
  color: var(--color-text-secondary);
}
.submit {
  padding: 0 12px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  margin-top: 12px;
}
.submitButton {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.submitButton > div {
  justify-content: space-between;
  width: 100%;
}
.submit__video {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: fit-content;
  min-width: 90px;
}
.loader {
  border-radius: 12px 12px 0 0;
}

.buttonPrice {
  font-size: 16px !important;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.18000000715255737px;
  text-align: center;
  height: 48px;
  margin-bottom: 12px;
  margin-top: 12px;
}

@media (max-width: 1024px) {
  .root {
    width: calc(min(calc(100vw - 316px),948px) / 3 - 24px / 3)
  }
}

@media (min-width: 600px) {
  .image {
    height: 228px;
  }
  .data {
    padding: 8px 12px;
    min-height: 80px;
    box-sizing: border-box;
  }
  .submit {
    padding: 0 12px 12px;
  }
  .videoContainer {
    width: 100%;
    height: 220px;
    border-radius: 12px 12px 0 0;
    overflow: hidden;
  }
  .video {
    width: 100%;
    height: 220px;
    object-fit: cover;
    object-position: center;
  }
}
