.checkbox {
  cursor: pointer;
  background: var(--color-background-primary);
  width: 24px;
  height: 24px;
}
.checkbox[is-disabled='true'] {
  cursor: default;
}

.label {
  display: flex;
  background-position: center;
  background-size: 24px;
  width: 24px;
  height: 24px;
  transition: background-image var(--animation-delay) ease-out;
}

.label[is-disabled='true'] {
  opacity: 0.4;
  cursor: default;
}
.label[is-disabled='false'] {
  opacity: 1;
  cursor: pointer;
}
.label[is-checked='true'] {
  background-image: var(--img-ic-24-checkbox-selected);
}
.label[is-checked='false'] {
  background-image: var(--img-ic-24-checkbox-deselected);
}

.input {
  display: none;
}
