.container {
  width: 100%;
  height: 85svh;
  overflow: scroll;
  -ms-overflow-style: none;
}
.container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

@media (min-width: 1024px) {
  .container {
    width: 564px;
    height: 616px;
    max-height: 85svh;
  }
}
