.root {
  width: 112px;
  border-radius: 4px;
  background: var(--color-background-primary);
  box-shadow: var(--elevation-4-dp);
  cursor: default;
}
.imageContainer {
  position: relative;
  width: 112px;
  height: 96px;
}
.quantity {
  position: absolute;
  background: var(--color-background-secondary);
  top: 10px;
  right: 10px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.quantity p {
  color: var(--color-text-on-color);
  text-align: center;
}
.image {
  width: 112px;
  height: 96px;
  object-fit: contain;
  object-position: center;
}
.data {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  height: 64px;
}
.name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  display: -webkit-box;
  line-clamp: 2;
  box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.data p {
  max-width: 70px;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
