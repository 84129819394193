.background {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  height: 20px;
  border-radius: 10px;
}
.primary {
  background: var(--color-background-primary) !important;
}
.secondary {
  background: var(--color-background-order) !important;
}
.propgress {
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--color-background-secondary);
  transition: transform 0.2s ease;
}
.dots {
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: space-around;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
}
.dot {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  border-radius: 50%;
  height: 2px;
  width: 2px;
  background: var(--color-icon-secondary);
}
.indicator {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 10px;
  background: var(--color-icon-secondary);
  transition: left 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.indicatorIcon {
  width: 16px;
  height: 16px;
}
