.root {
  width: 100%;
  height: 85svh;
}
.header {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 16px 12px;
}
.container {
  padding-bottom: 80px;
}
.content {
  max-height: calc(85svh - 80px - 56px);
  overflow: scroll;
  box-sizing: border-box;
  padding: 12px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.submit {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  background: var(--color-background-primary);
}
.submitButtonContent,
.submitButtonContent p {
  font: inherit;
  color: var(--color-text-on-color);
}
.submitButtonContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
