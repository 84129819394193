.card {
  background: var(--color-background-primary);
  padding: 16px 8px;
  border-radius: 16px;
  height: fit-content;
}

.profileDataContainer {
  position: relative;
  isolation: isolate;
}

.customerData {
  min-height: 36px;
  position: relative;
  isolation: isolate;
}

.profileName {
  padding-right: 40px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profilePhone {
  padding-right: 40px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profilePhone[is-enough-customer-data='true'] {
  margin-top: 4px;
  color: var(--color-text-secondary);
}

.editProfileButton {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background-color: var(--color-button-secondary-default);
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-size: 24px 24px;
  background-position: center;
  background-image: url('/src/shared/ui-kit/images/ic/16/edit_secondary.svg');
  background-repeat: no-repeat;
}

.loyaltyProgramContainer {
  margin-top: 16px;
  border-radius: 20px;
  padding: 16px;
  /* TODO: background: var(--Product-Colors-PrimaryVariant); */
  background: #746bd9;
  cursor: pointer;
}

@media (min-width: 500px) {
  .card {
    padding: 16px;
  }

  .loyaltyProgramContainer {
    padding: 20px;
  }
}

@media (min-width: 1024px) {
  .card {
    padding: 30px;
    border-radius: 40px;
  }

  .logOutButton {
    margin-top: 16px;
  }
}
