.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.emptyContentContainer {
  height: calc(85svh - 56px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
}
.emptyMessage {
  color: var(--color-text-secondary);
}
.pendingContainer {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media (min-width: 500px) {
  .emptyContentContainer {
    height: calc(628px - 68px);
    max-height: calc(85svh - 68px);
    padding: 0 20px;
  }
}
