.navigation {
  background: var(--color-background-primary);
}
.catalog {
  margin: 0 auto;
  max-width: 1196px;
  padding: 0px 34px 0;
  margin-top: 24px;
  display: flex

}
.catalogContent {
  padding-left: 20px;
  max-width: min(calc(100vw - 316px),948px);
  min-height: calc(100dvh - 104px);
  width: calc(100% - 228px)
  /*padding: 0 10px;*/
}
.cartButtonMobile {
  position: sticky;
  bottom: 0;
  left: 0;
  padding: 16px;
  background: var(--color-background-primary);
  border-top: 1px solid var(--color-divider-divider);
}
.cartButtonMobile__button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cartButtonMobile__button,
.cartButtonMobile__button > p {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
}

.catalogCategory {
  max-width: 1360px;
  width: 100%;
  /*padding: 0 20px;*/
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
}
.catalogCategory:not(:first-child) {
  scroll-margin: calc(62px + 48px + 32px + 8px);
}
.catalogCategory:first-child {
  scroll-margin: calc(118px + 180px + 72px + 24px + 32px);
  padding-top: 24px;
}
.categoryProducts {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 12px;
}
.catalogCard {
  width: calc(100% / 2 - 10px);
  /* max-width: 224px; */
}
.constructorCatalogCard {
  width: 100%;
}
.videoCatalogCard {
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1;
  max-width: 100%;
}

@media (min-width: 600px) {
  .catalogCategory {
    /*padding: 0 20px;*/
    
  }
  .catalogCategory:not(:first-child) {
    scroll-margin: calc(80px + 84px + 24px + 32px);
    padding-top: 36px;
  }
  .catalogCategory:first-child {
    scroll-margin: calc(80px + 348px + 32px);
  }
  .catalogCard,
  .videoCatalogCard {
  }
}




@media (min-width: 1024px) {
  .catalogCard,
  .videoCatalogCard {
    width: calc(min(calc(100vw - 316px), 948px) / 3 - 24px / 3)
  }
}

@media (min-width: 1440px) {
  .catalogCard,
  .videoCatalogCard {
    width: calc(min(calc(100vw - 316px), 948px) / 4 - 9px)
  }
}


@media (min-width: 650px) {
  .catalogCard,
  .videoCatalogCard {
  }
}
@media (min-width: 860px) {
  .catalogCard,
  .videoCatalogCard {
  }
}
@media (min-width: 1024px) {
  .catalogCard,
  .videoCatalogCard {
  }
}
