.cartModal {
  z-index: 20;
  width: 428px;
  min-height: 272px;
  max-height: 524px;
  box-sizing: border-box;
  border-radius: 40px;
  padding: 30px;
  background: var(--color-background-primary);
  box-shadow: var(--elevation-16-dp);
}

.overlay {
  width: 100%;
  height: 100%;
  z-index: 20;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: var(--color-background-overlay);
}

.header {
  display: flex;
  align-items: center;
}

.cartTitle {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.clearButton {
  width: fit-content !important;
  margin-left: auto;
}

.productsList {
  margin-top: 16px;
  max-height: 348px;
  overflow-y: scroll;
}

.submitButton {
  margin-top: 16px;
  display: flex;
  align-items: center;
}
.buttonLabel {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--color-text-on-color);
}
.buttonPrice {
  margin-left: auto;
  color: var(--color-text-on-color);
}

.cartModal[animation-in='true'] {
  animation: animation-in var(--animation-delay) ease-in forwards;
}
.cartModal[animation-out='true'] {
  animation: animation-out var(--animation-delay) ease-out forwards;
}

.overlay[animation-in='true'] {
  animation: animation-in var(--animation-delay) ease-in forwards;
}
.overlay[animation-out='true'] {
  animation: animation-out var(--animation-delay) ease-out forwards;
}

@keyframes animation-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes animation-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.emptyCartImage {
  margin: 16px auto 0;
  width: 72px;
  height: 72px;
  object-fit: cover;
  display: block;
}
.emptyCartTitle {
  text-align: center;
  display: block;
  margin: 16px auto 0;
}
.emptyCartMessage {
  white-space: pre-wrap;
  text-align: center;
  display: block;
  margin: 8px auto 0;
  color: var(--color-text-secondary);
}
