.root {
  background: var(--color-button-secondary-default);
  border-radius: 12px;
  cursor: pointer;
}
.error {
  border: 2px solid var(--color-field-error);
}
.icon {
  margin-right: 16px;
}
.container {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.containerError {
  padding: 6px 14px;
}
.data {
  box-sizing: border-box;
  padding: 12px 26px 0 0;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.label {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--color-text-secondary);
}

.labelError {
  color: var(--color-text-error);
}
.value {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--color-text-primary);
}
.value__secondary {
  color: var(--color-text-secondary);
}
.exposeButton {
  position: absolute;
  top: 14px;
  right: 16px;
}
