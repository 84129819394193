.root {
  padding: 24px 16px 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 168px;
  box-sizing: border-box;
}
.message {
  padding: 0 16px;
  color: var(--color-text-secondary);
}
.pendingContainer {
  position: absolute;
  width: calc(100% - 32px);
  height: calc(100% - 40px);
  z-index: 1px;
  background: var(--color-background-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}
.link {
  color: var(--color-text-tertiary);
  text-decoration: none;
}
.link:not(:last-child) {
  margin-bottom: 12px;
}

@media (min-width: 500px) {
  .root {
    padding: 24px 20px 20px;
    min-height: 176px;
  }
  .pendingContainer {
    width: calc(100% - 40px);
    height: calc(100% - 44px);
  }
  .message {
    padding: 8px 20px 0;
  }
}
