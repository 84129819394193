.root {
  width: fit-content;
}
.avatar {
  cursor: pointer;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  object-position: center;
  width: 90px;
  height: 90px;
}
.input {
  display: none;
}
