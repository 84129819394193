.container {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  background: var(--color-background-primary);
  box-shadow: var(--elevation-4-dp);
  z-index: 20;
}
.link {
  padding: 0;
  margin: 0;
  color: var(--color-text-tertiary);
  cursor: pointer;
  text-decoration: none;
}
.text {
  width: 236px;
}

.button {
  padding: 0;
  margin-left: auto;
  background: var(--color-button-primary-default);
  cursor: pointer;
  color: var(--color-text-on-color);
  padding: 6px 22px;
  border-radius: 8px;
}

@media (min-width: 600px) {
  .container {
    width: fit-content;
    left: 50%;
    bottom: 12px;
    transform: translate(-50%, 0);
    border-radius: 12px;
    padding: 10px 12px;
    width: max-content;
    gap: 20px;
  }
  .button {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    color: var(--color-text-tertiary);
    cursor: pointer;
  }
  .text {
    width: 100%;
  }
}

.containerEnter {
  opacity: 0;
}
.containerEnterActive {
  transition: opacity var(--animation-delay) ease-out;
  opacity: 1;
}
.containerExit {
  opacity: 1;
}
.containerExitActive {
  opacity: 0;
  transition: opacity var(--animation-delay) ease-out;
}
