.root {
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.goToOrderButton {
  color: var(--color-text-tertiary);
  text-align: right;
  background: none;
  border: none;
  width: 100%;
}
.transactionData {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.transactionAmount {
  display: flex;
  align-items: center;
  gap: 4px;
}
.withdrawTransaction {
  color: var(--color-text-subtraction);
}
.bonusImage {
  background-image: var(--img-ic-24-bonus);
}
