.root {
  position: relative;
  display: block;
  border: none;
  text-align: center;
  box-shadow: none;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
}
.withLoaderLarge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px !important;
}
.withLoaderMedium {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px !important;
}

.large {
  padding: 12px 16px;
  border-radius: 12px;
  font-family: var(--headline-font-family) !important;
  font-size: var(--headline-font-size) !important;
  font-style: var(--headline-font-style) !important;
  font-weight: var(--headline-font-weight) !important;
  line-height: var(--headline-line-height) !important;
  letter-spacing: var(--headline-letter-spacing) !important;
}
.medium {
  padding: 8px 12px;
  border-radius: 10px;
  font-family: var(--caption-2-font-family) !important;
  font-size: var(--caption-2-font-size) !important;
  font-style: var(--caption-2-font-style) !important;
  font-weight: var(--caption-2-font-weight) !important;
  line-height: var(--caption-2-line-height) !important;
  letter-spacing: var(--caption-2-letter-spacing) !important;
}

.primary {
  background: var(--color-button-primary-default);
  color: var(--color-text-on-color);
}
.secondary {
  background: var(--color-button-secondary-default);
  color: var(--color-text-primary);
}

.outline {
  background: none;
  color: var(--color-text-primary);
}

.primary:active {
  background: var(--color-button-primary-ripple);
}
.secondary:active {
  background: var(--color-button-secondary-ripple);
}

.primary:disabled:active,
.primary:disabled {
  background: var(--color-button-primary-disable);
  color: var(--color-text-on-color-disabled);
}
.secondary:disabled:active,
.secondary:disabled {
  background: var(--color-button-secondary-disable);
  color: var(--color-text-disabled);
}
