.root {
  width: 100%;
}
.input {
  padding: 0 16px;
}
.list {
  padding: 0 16px;
  min-height: calc(292px - 56px - 52px);
  max-height: calc(85svh - 56px - 52px);
}
.listItem {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.listItem:not(:last-child) {
  margin-bottom: 12px;
}
.flag {
  height: 16px;
  object-fit: cover;
}
.listContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 0;
}

.placeholderContainer {
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 560px) {
  .root {
    width: 472px;
  }
  .list {
    min-height: calc(292px - 56px - 52px);
    max-height: calc(85svh - 56px - 52px);
    padding: 0 20px;
  }
  .listContent {
    display: block !important;
    padding: 20px 0;
    column-count: 2 !important;
  }
  .input {
    padding: 0 20px;
  }
}
