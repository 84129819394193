.item {
  padding: 0 16px;
  box-sizing: border-box;
  width: 100%;
}
.item:not(:last-child) > .content {
  border-bottom: 1px solid var(--color-divider);
}
.content {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 26px 12px 0;
  gap: 16px;
}
.activeImage {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}

@media (min-width: 500px) {
  .item {
    padding: 0 12px;
  }
  .content {
    border-bottom: none !important;
  }
}
