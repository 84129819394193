.informationBanner {
  max-height: 85svh;
  width: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
}
.informationBanner::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.icon {
  margin: 32px auto 0;
  width: 72px;
  height: 72px;
  object-fit: cover;
  display: block;
}

.title {
  margin: 16px auto 0;
  padding: 0 16px;
  text-align: center;
  max-width: 336px;
  display: block;
}

.body {
  margin: 8px auto 0;
  padding: 0 16px;
  text-align: center;
  max-width: 336px;
  display: block;
  color: var(--color-text-secondary);
  white-space: pre-wrap;
}

.submit {
  width: 100%;
  box-sizing: border-box;
  margin-top: 16px;
  padding: 20px;
}

@media (min-width: 500px) {
  .informationBanner {
    width: 368px;
  }
}
