.swiper-button-prev,
.swiper-button-next {
  background-color: var(--color-background-primary) !important;
  width: 48px !important;
  height: 48px !important;
  border-radius: 24px !important;
  box-shadow: var(--elevation-4-dp) !important;
  opacity: 1;
  transition: opacity var(--animation-delay) linear;
}
.swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 8px;
  z-index: 10;
  background-image: var(--img-ic-24-chevron-right);
  background-size: 28px;
  background-position: center;
  background-repeat: no-repeat;
}
.swiper-button-prev {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%) rotate(180deg);
  left: 8px;
  z-index: 10;
  background-image: var(--img-ic-24-chevron-right);
  background-size: 28px;
  background-position: center;
  background-repeat: no-repeat;
}
.swiper-button-disabled {
  opacity: 0;
}

@media (min-width: 600px) {
  .swiper-button-next {
    right: 42px;
  }
  .swiper-button-prev {
    left: 42px;
  }
}
