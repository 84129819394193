.languageLayout {
  display: flex;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  width: 100%;
  height: 32px;
  z-index: 10;
  transition: 0.1s;
  max-width: 1196px;
  justify-content: space-between;
}
.hideLayout {
  visibility: hidden;
}

.languageLayoutContainer {
  max-width: 1280px;
  display: flex;
  align-items: center;
}
.languageButton {
  margin-right: 8px;
  background-color: #f5f5f5 !important;
  padding: 4px 6px 4px 6px;
}

.footer {
  width: 100%;
  background: var(--color-background-footer);
}
.footerContainer {
  padding: 24px 16px 20px;
  margin: 0 auto;
  max-width: 1280px;
}
.footerLogo {
  height: 36px;
  width: 36px;
  object-position: center;
}

.deliveryAndPhoneContainer {
  display: flex;
  gap: 20px;
  align-items: center;
}

.deliveryAndPhoneContainerHide {
  display: none;
}

.informationListItem {
  color: var(--color-text-secondary);
  cursor: pointer;
  text-decoration: none;
  font-family: var(--caption-2-font-family) !important;
  font-size: var(--caption-2-font-size) !important;
  font-weight: 600 !important;
  line-height: var(--caption-2-line-height) !important;
  text-align: right;
}

.dataValue {
  color: var(--color-text-secondary) !important;
  text-decoration: none;
  font-family: var(--caption-2-font-family) !important;
  font-size: var(--caption-2-font-size) !important;
  font-weight: 600 !important;
  line-height: var(--caption-2-line-height) !important;
  text-align: right;
}

.footerData {
  padding-top: 20px;
}
.footerContacts {
  border-bottom: 1px solid var(--color-divider-divider);
}
.footerContactsData {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.contactsSubtitle {
  color: var(--color-text-secondary);
}
.contactEvents {
  padding-top: 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 23px;
}
.contactButton {
  width: fit-content;
}
.chatButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}
.footerInfo {
  padding-top: 24px;
  border-bottom: 1px solid var(--color-divider-divider);
}
.footerInfoContainer {
  padding-bottom: calc(23px);
}
.footerInfoList {
  padding-top: 12px;
}
.footerInfoListItem {
  color: var(--color-text-secondary);
  cursor: pointer;
}
.footerInfoListItem:not(:last-child) {
  padding-bottom: calc(12px);
}
.footerInfoListItem:last-child {
  padding-bottom: calc(0);
}
.footerLegalInfo {
  padding-top: 24px;
  border-bottom: 1px solid var(--color-divider-divider);
}
.footerLegalInfoContainer {
  padding-bottom: calc(23px);
}
.footerLegalInfoList {
  padding-top: 12px;
}
.footerLegalInfoListItem {
  color: var(--color-text-secondary);
  cursor: pointer;
}
.footerLegalInfoListItem:not(:last-child) {
  padding-bottom: calc(12px);
}
.footerLegalInfoListItem:last-child {
  padding-bottom: calc(0);
}
.footerSubscription {
  padding-top: 24px;
  border-bottom: 1px solid var(--color-divider-divider);
}
.footerSubscriptionContainer {
  padding-bottom: 23px;
}
.footerSubscriptionMessage {
  padding-top: 12px;
  color: var(--color-text-secondary);
}
.footerSubscriptionInputs {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.footerSubscriptionButton {
  width: 100%;
}
.footerAppsNSocials {
  padding-top: 24px;
}
.footerApps {
  border-bottom: 1px solid var(--color-divider-divider);
}
.footerAppsContainer {
  padding-bottom: 23px;
}
.footerAppsList {
  padding-top: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
}
.footerApp {
  cursor: pointer;
  height: 40px;
  width: auto;
  object-fit: cover;
  object-position: center;
}
.footerSocials {
  border-bottom: 1px solid var(--color-divider-divider);
  padding-top: 24px;
}
.footerSocialsContainer {
  padding-bottom: 23px;
}
.footerSocialsList {
  padding-top: 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.footerSocial {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.legalInfo {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.legalMessage {
  color: var(--color-text-secondary);
}
.legalLink {
  color: var(--color-text-secondary);
  cursor: pointer;
  display: none;
}

@media (max-width: 1024px) and (min-width: 500px) {
  .languageLayout {
    position: relative;
    top: 0;
  }
}

@media (min-width: 460px) {
  .footerContactsData {
    flex-direction: row;
    gap: 80px;
  }
  .footerInfoContainer {
    padding-bottom: calc(19px - 12px);
  }
  .footerInfoList {
    column-count: 2 !important;
    column-gap: 150px;
  }
  .footerLegalInfo {
    display: none;
  }
  .legalLink {
    display: block;
  }
}

@media (max-width: 500px) {
  .languageLayout {
    background: var(--color-background-primary);
    position: relative;
  }
}

@media (min-width: 500px) {
  .languageLayout {
    z-index: 20;
  }
}

@media (min-width: 600px) {
  .languageLayout {
    margin: 0 auto;
    box-sizing: unset;
    left: 50%;
    transform: translate(-50%);
  }
  .footerContainer {
    padding: 40px 42px 20px;
  }
  .footerContacts {
    border-bottom: none;
  }
  .footerContactsData {
    flex-direction: column;
    gap: 12px;
  }
  .contactEvents {
    padding-bottom: 0;
  }
  .footerDataContainer {
    padding-bottom: 19px;
    border-bottom: 1px solid var(--color-divider-divider);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
  }
  .footerInfo {
    padding-top: 0;
    border-bottom: none;
    padding-left: calc(25px - 20px);
  }

  .footerInfoContainer {
    padding-bottom: 0;
  }
  .footerInfoList {
    column-count: 1 !important;
    column-gap: normal;
  }
  .footerSubscriptionButton {
    width: fit-content;
  }
  .footerSubscriptionInputs {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
  .footerAppsNSocials {
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  .footerSubscription {
    max-width: 438px;
    padding-top: 0;
    border-bottom: none;
  }
  .footerApps {
    border-bottom: none;
  }
  .footerAppsContainer {
    padding-bottom: 0;
  }
  .footerSocials {
    border-bottom: none;
    padding-top: 0;
  }
  .footerSocialsContainer {
    padding-bottom: 0;
  }
  .legalInfo {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .legalLink {
    text-align: right;
    white-space: nowrap;
  }
}
@media (min-width: 1024px) {
  .footerSubscription {
    padding-left: calc(117px - 20px);
  }
}
