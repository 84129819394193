.root {
  position: relative;
  z-index: 2;
}
.openButton {
  border: none;
  appearance: none;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: none;
}
.profileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profileImg {
  height: 24px;
  width: 24px;
  pointer-events: none;
  padding: 6px 14px 6px;
}
.cart {
  padding-right: 16px;
}
.cartButton {
  position: relative;
  min-width: 140px;
  width: fit-content;
}
.openButton__mobile {
  background-image: var(--img-ic-24-menu);
}
.closeButton {
  background-image: var(--img-ic-24-close);
}
.bonusLabel {
  position: absolute;
  box-sizing: border-box;
  padding-left: 4px;
  padding-right: 4px;
  top: 2px;
  left: calc(50% + 2.5px);
  color: var(--color-text-on-color);
  background: var(--color-button-primary-default);
  border-radius: 100px;
  min-width: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Inter;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 16px !important; /* 145.455% */
}