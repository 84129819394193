.root {
  max-height: 85svh;
  width: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
  /*border-radius: 12px 12px 0 0;*/
}
.root::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.name {
  margin-top: 20px;
  padding: 0 20px;
}
.description {
  margin-top: 12px;
  padding: 0 20px;
}
.progressContainer {
  margin-top: 16px;
  padding: 0 20px;
}
.progressCaption {
  color: var(--color-text-secondary);
  margin-top: 8px;
}
.giftsContainer {
  margin-top: 24px;
  padding: 0 20px 20px;
}
.giftsList {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 20px;
}

.giftCard {
  width: calc(100% / 2 - 8px);
  max-width: 156px;
}

.image {
  width: 100%;
  height: auto;
  min-height: 200px;
  object-fit: contain;
  object-position: center;
}
.loader {
  background-color: var(--color-button-secondary-default);
}
.processingImage {
  width: 72px;
  height: 72px;
  animation: shine 1.8s infinite linear;
}
@keyframes shine {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 500px) {
  .root {
    /*border-radius: 12px;*/
    width: 368px;
  }
}
