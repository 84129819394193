.root {
  display: flex;
  align-items: center;
}
.root__noFill {
  gap: 12px;
}
.button {
  padding: 0;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button__large {
  width: 32px;
  height: 32px;
  border-radius: 8px;
}
.root__catalogVariant {
  background: var(--color-button-primary-default);
  height: 48px;
  border-radius: 12px;
}

.button__catalogVariant {
  color: var(--color-text-on-color);
}

.button__noFill {
  background: none !important;
}
.image {
  cursor: pointer;
}
.quantity {
  width: 32px;
  text-align: center !important;
}
.onColor {
  color: var(--color-text-on-color);
}
.quantity__noFill {
  width: 36px !important;
}
