.cart {
  padding-bottom: 88px;
}
.cartContent {
  height: calc(85svh - 88px);
  overflow: scroll;
  -ms-overflow-style: none;
}
.cartContent::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.warningContainer {
  padding: 0 16px 12px;
}
.longDeliveryWarningContainer {
  padding: 8px 12px;
  box-sizing: border-box;
  border-radius: 12px;
  width: 100%;
  background: var(--color-banner-warning);
}
.warningContent {
  background: var(--color-button-secondary-default);
  border-radius: 12px;
}
.warningMessage {
  padding: 16px 12px;
}
.pickers {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.nodeContainer {
  padding: 0 16px;
}
.productsList {
  display: flex;
  flex-direction: column;
  padding: 23px 0;
}

.promocodeContainer {
  padding-bottom: 24px !important;
}

.submit {
  box-shadow: var(--elevation-2-dp);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px 16px 16px;
  box-sizing: border-box;
  background: var(--color-background-primary);
}
.submitContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.submitContent,
.submitContent p {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
}
.fullPrice {
  opacity: 0.5;
}

.header {
  padding: 16px 16px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clearContainer {
  margin-top: 20px;
  padding: 0 16px;
}
.clearContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.clearButton {
  width: fit-content;
}
.mobileDividerContainer {
  padding: 0 16px;
  margin-top: 20px;
}
.mobileDivider {
  border-top: 1px solid var(--color-divider-divider);
  width: 100%;
}

@media (min-width: 600px) {
  .mobileDividerContainer {
    display: none;
  }
  .clearContainer {
    padding: 0 20px;
    margin-top: 24px;
  }
  .cart {
    padding-bottom: 92px;
  }
  .cartContent {
    height: calc(85svh - 92px);
  }
  .warningContainer {
    padding: 0 20px 12px;
  }
  .nodeContainer {
    padding: 0 20px;
  }
  .submit {
    padding: 24px 20px 20px;
    border-radius: 0 0 12px 12px;
  }
  .header {
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
