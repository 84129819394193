.root {
  padding: 0 16px;
}
.container {
  border-top: 1px solid var(--color-divider-divider);
  position: relative;
  padding: 8px 0 11px;
  display: flex;
  align-items: flex-start;
}
.root:not(:first-child) > .container {
  border-top: 1px solid var(--color-divider-divider);
}
.image {
  width: 56px;
  height: 56px;
  object-fit: contain;
  object-position: center;
}
.data {
  padding: 0 92px 0 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.fullPrice {
  color: var(--color-text-secondary);
}
.event {
  position: absolute;
  right: 0;
  top: 8px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 600px) {
  .root {
    padding: 0 20px;
  }
}
