.root {
  position: relative;
}
.mapContainer {
  width: 100%;
  height: 580px;
}
.map {
  width: 100%;
  height: 580px;
}
.restaurantsListContainer {
  position: absolute;
  top: 40px;
  left: calc((100% - 940px) / 2);
}

@media (min-width: 1440px) {
  .restaurantsListContainer {
    left: calc((100% - 1280px) / 2);
  }
}
