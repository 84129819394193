.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}
.dialog > * {
  user-select: none;
}

.container {
  pointer-events: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.content {
  pointer-events: all;
  border-radius: 40px;
  max-width: 500px;
  width: 100%;
  max-height: min(550px, 85dvh);
  height: 100%;
  background: var(--color-background-primary);
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
}

.header {
  display: flex;
  align-items: center;
  padding: 16px 30px 8px;
}

.title {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.closeButton {
  box-sizing: content-box;
  padding: 8px 0;
  width: 36px;
  height: 36px;
  margin-left: auto;
  background-repeat: no-repeat;
  background-position: center;
}

.code {
  padding: 0 30px;
  display: block;
}
.codeWrapper {
  margin: 0 auto;
  width: fit-content;
  height: fit-content;
  padding: 28px 27px 26px;
  border-radius: 12px;
  background: var(--Background-QR, #ebebf0);
}
.code img {
  max-width: 306px !important;
  max-height: 306px !important;
  width: 100% !important;
  height: 100% !important;
  box-sizing: border-box !important;
  aspect-ratio: 1 / 1 !important;
  object-fit: contain !important;
}
.message {
  text-align: center;
  display: block;
  margin: 24px auto 0;
  max-width: 440px;
  padding: 0 30px;
  white-space: pre-wrap;
}

.containerEnter {
  opacity: 0;
}
.containerEnterActive {
  transition: opacity var(--animation-delay) ease-out;
  opacity: 1;
}
.containerExit {
  opacity: 1;
}
.containerExitActive {
  opacity: 0;
  transition: opacity var(--animation-delay) ease-out;
}
