.categories {
  width: 100%;
  position: sticky;
  top: calc(102px + 24px);
  left: 0;
  background: var(--color-background-primary);
  z-index: 10;
}
.dividerContainer {
  border-bottom: none;
  transition: border-bottom var(--animation-delay) linear;
}
.dividerContainer__crossing {
}
.categoriesContent {
  display: flex;
  align-items: center;
  height: 64px;
}
.categoriesList {
  box-sizing: border-box;
  padding: 6px 0;
  display: flex;
  gap: 8px;
  width: 100%;
  overflow: scroll;
  z-index: 1 !important;
}
.categoriesList::-webkit-scrollbar {
  width: 0;
}
.categoriesContainer {
  border-radius: 24px 24px 0 0;

}
.category {
  width: fit-content !important;
}
.category:first-child {
  padding-left: 16px;
}
.category:last-child {
  padding-right: 16px;
}
.cart {
  padding-right: 16px;
}
.cartButton {
  position: relative;
  min-width: 140px;
  width: fit-content;
}
.shadowBlock {
  z-index: 1;
  position: absolute;
  top: 0;
  left: -90px;
  width: 90px;
  height: 48px;
  pointer-events: none;
  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}



@media (min-width: 1024px) {
  .categories {
    top: calc(84px + 31px);
  }
  .categoriesList {
    width: calc(100% - 140px);
  }
  .categoriesContainer {
    padding-top: 16px;
    padding-bottom: 12px;
  }
  .cart {
    padding-right: 42px;
  }
  .category:first-child {
    padding-left: 42px;
  }
  .category:last-child {
    padding-right: 90px;
  }
}

@media (min-width: 1440px) {
  .cart {
    padding-right: calc((100vw - 1280px) / 2);
  }
  .categoriesList {
    position: relative;
    max-width: calc(1280px + (42px * 2) - 90px - 42px);
    margin-left: calc((100vw - 1280px) / 2 - 42px);
  }
  .categoriesList::before {
    bottom: 0;
    content: '';
    position: absolute;
    z-index: 2;
    left: -1px;
    width: 42px;
    height: 100%;
    background: linear-gradient(
      90deg,
      #ffffff 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .category:first-child {
    padding-left: 42px;
  }
  .category:last-child {
    padding-right: 90px;
  }
}

@media (max-width: 500px) {
  .categories {
    top: 64px;
    border-radius: 24px 24px 0px 0px;
    margin-top: -64px;
  }
}
