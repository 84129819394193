.root {
  position: relative;
  min-height: calc(100svh - 118px - 60px - 38px - 36px);
}

.searchField {
  padding: 10px 16px;
}
.errorConntainer {
  padding: 8px 16px 0;
}
.reload {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
}
.placeholderContainer {
  padding-top: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.restaurantsList {
  display: flex;
  flex-direction: column;
  min-height: 375px;
  position: relative;
}

@media (min-width: 500px) {
  .searchField {
    padding: 10px 20px;
  }
}
