.root {
  width: fit-content;
  height: fit-content;
  border-radius: 8px;
  background: #F5F5F5;
  padding: 4px 6px 4px 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.icon {
  margin-right: 4px;
  width: 16px;
  height: 16px;
  object-fit: cover;
}
.value {
  color: var(--color-text-secondary);
}
