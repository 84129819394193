.root {
  background: var(--color-background-primary);
  position: relative;
  border-radius: 12px;
  cursor: pointer;
  padding: 7px 11px;
  border: 1px solid var(--color-field-inactive);
}
.root__open {
  border: none !important;
  padding: 8px 12px !important;
  box-shadow: var(--elevation-2-dp) !important;
}
.disabled {
  opacity: 0.5;
}
@media (min-width: 500px) {
  .root__open {
    border-radius: 12px 12px 0px 0px !important;
  }
}
.root__error {
  padding: 6px 10px;
  border: 2px solid var(--color-field-error);
}

.container {
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
}
.image {
  padding-left: 4px;
}
.data {
  position: relative;
  padding: 12px 26px 0 0;
  height: 24px;
  overflow: hidden;
  width: 100%;
}
.floatingLabel {
  position: absolute;
  width: 100%;
  top: 6px;
  left: 0;
  pointer-events: none;
  transform-origin: left top;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
  color: var(--color-text-secondary);
  will-change: transform;
}
.floatingLabel__active {
  transform: translateY(-30%) scale(0.75);
}
.floatingLabel__error {
  color: var(--color-text-error);
}
.value {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.exposeButton {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 0;
}
