.root {
  width: 100%;
  /* min-height: 70vh; */
  max-height: 600px;
  height: 85svh;
}
.segmentedControl {
  padding: 16px 16px 8px;
}
.city {
  padding: 0 16px 8px;
}
.cartographyLoader {
  max-height: 600px;
  height: 85svh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 500px) {
  .root {
    width: 428px;
    overflow: hidden;
  }
  .segmentedControl {
    padding: 20px 20px 8px;
  }
  .city {
    padding: 0 20px 8px;
  }
}
