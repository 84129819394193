.root {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
}
.container {
  width: max-content;
  background: var(--color-background-primary);
  border-radius: 20px;
  box-shadow: var(--elevation-4-dp);
}
.data {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
}
.submit {
  padding: 12px 20px 20px;
  display: flex;
  align-items: flex-start;
  gap: 12px;
}
.submit > button {
  min-width: max-content !important;
  width: 50%;
}

.containerEnter {
  opacity: 0;
}
.containerEnterActive {
  transition: opacity var(--animation-delay) ease-out;
  opacity: 1;
}
.containerExit {
  opacity: 1;
}
.containerExitActive {
  opacity: 0;
  transition: opacity var(--animation-delay) ease-out;
}
