.searchField {
  padding: 0px 16px;
}
.errorConntainer {
  padding: 8px 16px 0;
}
.reload {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
}
.placeholderContainer {
  padding-top: 98px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 500px) {
  .searchFiled {
    padding: 0px 20px;
  }
  .errorConntainer {
    padding: 0px 20px;
  }
  .reload {
    padding: 16px 20px 20px;
  }
}
