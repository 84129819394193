.root {
  position: relative;
  min-height: calc(100svh - 118px - 60px - 38px - 36px);
}
.name {
  padding: 0 16px;
  margin-bottom: 16px;
}

.segmentedControl {
  padding: 0 16px;
  margin-bottom: 16px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 16px;
  margin-bottom: 18px;
}
.headerContent {
  padding: 12px 0;
  border-bottom: 1px solid var(--color-divider-divider);
}
.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.address {
  color: var(--color-text-secondary);
}
.worktime {
  color: var(--color-text-tertiary);
}
.worktime__Closed {
  color: var(--color-text-secondary);
}
.distanceTo {
  color: var(--color-text-secondary);
}
.schedule {
  padding-bottom: 132px;
}
.scheduleTitle {
  margin-bottom: 16px;
  padding: 0 16px;
}
.scheduleItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}
.scheduleItem:not(:last-child) {
  margin-bottom: 16px;
}

.mapContainer,
.map {
  border-radius: 20px;
  height: 427px;
}
.mapContainer {
  padding: 0 16px 124px;
}
.map {
  width: 100%;
}

.buttonContainer {
  position: absolute;
  left: 0;
  bottom: 60px;
  padding: 0 16px;
  width: 100%;
  box-sizing: border-box;
}

@media (min-width: 500px) {
  .name {
    padding: 0 20px;
  }
  .segmentedControl {
    padding: 0 20px;
  }
  .header {
    padding: 0 20px;
  }
  .scheduleTitle {
    padding: 0 20px;
  }
  .scheduleItem {
    padding: 0 20px;
  }
  .buttonContainer {
    padding: 0 20px;
  }
  .mapContainer {
    padding: 0 20px 124px;
  }
}
