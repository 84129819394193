.container {
  box-sizing: border-box;
  border-radius: 16px;
  background: var(--color-background-primary);
  width: 100%;
  height: fit-content;
  padding: 16px 8px;
}
.icon {
  display: block;
  margin: 0 auto;
}
.title {
  text-align: center;
  display: block;
  margin: 16px auto 0;
}
.message {
  text-align: center;
  display: block;
  margin: 8px auto 0;
  color: var(--color-text-secondary);
  max-width: 308px;
}
.button {
  margin-top: 36px;
}

@media (min-width: 1024px) {
  .container {
    width: fit-content;
    border-radius: 40px;
    padding: 30px;
    box-sizing: content-box;
  }
}
