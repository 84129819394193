.root {
  padding: 20px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.picker {
  box-sizing: border-box;
  padding: 0 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 28px;
  height: 150px;
}
.years {
  width: 48px;
}
.months {
  width: 82px;
}
.days {
  width: 28px;
}
.days,
.years,
.months {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 150px;
}
.days > div,
.years > div,
.months > div {
  height: 100% !important;
}
.days::after,
.years::after,
.months::after {
  pointer-events: none;
  content: '';
  position: absolute;
  bottom: 58px;
  height: 1px;
  background-color: var(--color-icon-primary);
}
.days::before,
.years::before,
.months::before {
  pointer-events: none;
  content: '';
  position: absolute;
  top: 58px;
  height: 1px;
  background-color: var(--color-icon-primary);
}
.days::before,
.days::after {
  width: 28px;
}
.months::before,
.months::after {
  width: 82px;
}
.years::before,
.years::after {
  width: 48px;
}

.item {
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inactive {
  color: var(--color-text-secondary);
}

.submit {
  width: 100%;
}

@media (min-width: 500px) {
  .root {
    padding: 0 20px 20px;
  }
  .picker {
    padding: 0 53px;
  }
}
