.root {
  background: var(--color-background-primary);
  position: relative;
  border-radius: 12px;
  cursor: text;
  padding: 7px 11px;
  border: 1px solid var(--color-field-inactive);
}
.root[is-desabled='true'] {
  border: 1px solid var(--color-field-inactive);
}
.root__focused {
  border: none !important;
  padding: 8px 12px !important;
  box-shadow: var(--elevation-2-dp) !important;
}
.root__open {
  border-radius: 12px 12px 0 0;
  border-bottom: none;
}
.root__error {
  padding: 7px 11px;
  border: 1px solid var(--color-field-error);
}
.container {
  display: flex;
  align-items: center;
  position: relative;
}
.searchField {
  position: relative;
  padding: 6px 26px 6px 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
}
.searchField__input {
  resize: none;
  text-decoration: inherit;
  text-transform: inherit;
  align-self: flex-end;
  box-sizing: border-box;
  padding: 0;
  width: 100%;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  border: none;
  border-radius: 0;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}
.searchField__input:disabled,
.searchField__input:disabled::placeholder {
  color: var(--color-text-disabled);
}
.clearIcon {
  position: absolute;
  right: 0;
  top: 18px;
  transform: translateY(-50%);
  cursor: default;
  padding: 0;
  margin: 0;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: var(--img-ic-24-clear-field);
}
