.root {
  display: flex;
  flex-direction: column;
}
.container {
  background: var(--color-button-secondary-default);
  width: 100%;
  border-radius: 12px;
  position: relative;
}
.content {
  width: calc(100% - 56px);
  height: calc(100% - 28px);
  padding: 14px 0 14px 16px;
  margin-right: 40px;
  overflow: hidden;
}
.button {
  position: absolute;
  top: 14px;
  right: 16px;
}
.exposeButton {
  background-image: var(--img-ic-24-chevron-down) !important;
}
.clearButton {
  background-image: var(--img-ic-24-close) !important;
}

.messageContainer {
  padding: 12px 0 0;
}

.message {
  color: var(--color-text-secondary);
}
.messageFailed {
  color: var(--color-text-error);
}
