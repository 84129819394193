.base {
  display: flex;
  align-items: center;
  max-width: 288px;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 20px;
}
.info {
  display: flex;
  align-items: center;
  gap: 12px;
}
.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
