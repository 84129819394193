.phoneField {
  background: var(--color-background-primary);
  position: relative;
  border-radius: 12px;
  border: 1px solid var(--color-field-activated);
  display: flex;
  align-items: center;
}
.phoneField[has-error='true'] {
  border: 2px solid var(--color-field-error);
}
.phoneField[is-disabled='true'] {
  border: 1px solid var(--color-field-inactive);
  cursor: default;
}

.codeContainer {
  border-radius: 12px 0 0 12px;
  padding: 4px 0 4px 11px;
  cursor: pointer;
  width: fit-content;
}
.codeContainer[is-disabled='true'] {
  padding: 4px 0 4px 11px;
  opacity: 0.4;
  cursor: default;
}
.codeContainer[has-error='true'] {
  padding: 3px 0 3px 10px;
}

.textFieldContainer {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: text;
  border-radius: 0 12px 12px 0;
  padding: 13px 11px 13px 0;
}
.textFieldContainer[has-error='true'] {
  padding: 12px 10px 12px 0;
}

.codeContent {
  border-right: 1px solid var(--color-divider-divider);
}
.code {
  padding: 7px 8px 7px;
  display: flex;
  align-items: center;
  gap: 4px;
}
.input {
  margin-left: 12px;
  resize: none;
  text-decoration: inherit;
  text-transform: inherit;
  align-self: flex-end;
  box-sizing: border-box;
  padding: 0;
  width: 100%;
  border: none;
  border-radius: 0;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input:disabled {
  color: var(--color-text-disabled);
}

.flagIcon {
  height: 28px;
  width: 18px;
  object-position: center;
  object-fit: contain;
}

.codeModalRoot {
  z-index: 1;
  position: absolute;
  min-height: 52px;
  max-height: 168px;
  border-radius: 12px;
  background: var(--color-background-primary);
  box-shadow: var(--elevation-4-dp);
  display: flex;
  flex-direction: column;
  overflow: scroll;
  -ms-overflow-style: none;
}
.codeModalRoot::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.codeModalRoot__top {
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
}
.codeModalRoot__bottom {
  bottom: -1px;
  left: -1px;
  width: calc(100% + 2px);
}

.codeListItem {
  cursor: pointer;
  padding: 0 16px;
}
.codeListItemContainer:not(:last-child) {
  border-bottom: 1px solid var(--color-divider-divider);
}
.codeListItemContent {
  padding: 12px 0 11px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.codeData {
  display: flex;
  align-items: center;
}
.codeFlag {
  width: 15px;
  height: 11px;
  margin-right: 11px;
  object-fit: contain;
  object-position: center;
}
