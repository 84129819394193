.root {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
}
.image {
  width: 72px;
  height: 72px;
  object-fit: contain;
  object-position: center;
}
.data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.title {
  text-align: center;
  color: var(--color-text-secondary);
}
.message {
  text-align: center;
  color: var(--color-text-secondary);
  max-width: 312px;
}
