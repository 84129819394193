.root {
  background: var(--color-background-primary);
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
}

.inputContainer {
  display: flex;
  gap: 20px;
}
.input {
  flex: 2;
}
.submit {
  flex: 1;
}

.messageContainer {
  padding: 12px 0 0;
}

.message {
  color: var(--color-text-secondary);
}
.messageFailed {
  color: var(--color-text-error);
}
