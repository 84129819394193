.root {
  width: 100%;
}
.info {
  color: var(--color-text-secondary);
  padding: 0 16px;
}
.code {
  padding: 24px 0 0;
}
.timer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0 16px;
}
.timerMessage {
  max-width: 328px;
  color: var(--color-text-secondary);
  text-align: center;
}
.submit {
  padding: 0 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  box-sizing: border-box;
}

@media (min-width: 500px) {
  .root {
    width: 400px;
  }
  .info {
    padding: 0 20px;
  }
  .submit {
    padding: 0 20px 20px;
  }
}
