.root {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 16px 16px;
  box-sizing: border-box;
  width: 100%;
}
.message {
  padding: 0 0 12px;
}
@media (min-width: 500px) {
  .root {
    padding: 0 20px 20px;
    width: 368px;
  }
}
