.promotionsList {
  padding-top: 12px;
  display: flex;
  flex-direction: column;
}
.fetchingContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-background-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px 24px 0 0;
}

@media (min-width: 500px) {
  .promotionsList {
    padding-top: 0;
  }
  .fetchingContainer {
    border-radius: 12px;
  }
}
