.navigateButton {
  border: none;
  background-color: var(--color-button-secondary-default);
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-size: 24px 24px;
  background-position: center;
  background-image: url('/src/shared/ui-kit/images/ic/24/chevron_right.svg');
  background-repeat: no-repeat;
}
