.root {
  position: relative;
  width: 100%;
  height: 20px;
}
.root::before {
  position: absolute;
  content: '';
  height: 6px;
  width: 99%;
  border-radius: 3px;
  background: var(--color-button-primary-default);
  opacity: 0.6;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.cover {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 0%;
  height: 6px;
  border-radius: 3px;
  background: var(--color-button-primary-default);
  /* z-index: 1; */
  user-select: none;
  pointer-events: none;
}
.thumb {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  pointer-events: none;
  background: var(--color-button-primary-default);
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  /* z-index: 2; */
  pointer-events: none;
}
.input {
  appearance: none;
  opacity: 0;
  height: 4px;
  width: 100%;
  cursor: pointer;
  margin: 0 !important;
  border: none;
  border-radius: 3px;
  outline: none;
}
.input::-webkit-slider-thumb {
  appearance: none;
  background: var(--color-button-primary-default);
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}
.input::-moz-range-thumb {
  appearance: none;
  background: var(--color-button-primary-default);
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}
