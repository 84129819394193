.root {
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0 16px;
}
.root:not(:last-child) .content {
  border-bottom: 1px solid var(--color-divider-divider);
}
.content {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  padding: 8px 0 11px;
  display: flex;
  gap: 15px;
  align-items: flex-start;
}
.content__disabled {
  opacity: 0.5;
}
.image {
  width: 56px;
  height: 56px;
  object-fit: contain;
  object-position: center;
}
.data {
  padding-right: 90px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.active {
  position: absolute;
  right: 0;
  top: 8px;
}
