.root {
  min-height: calc(100vh - 118px - 32px);
  width: 100%;
}
.loaderContainer {
  width: 100%;
  height: calc(100vh - 118px - 32px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.breadCrumbs {
  padding: 20px 16px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  max-width: 1280px;
}
.breadCrumbs > span {
  color: var(--color-text-secondary);
}
.title {
  padding: 0 16px;
  max-width: 1280px;
}

.restaurantsLayout {
  margin-top: 36px;
}

@media (min-width: 500px) {
  .breadCrumbs {
    padding: 20px;
  }
  .title {
    padding: 0 20px;
  }
}

@media (min-width: 600px) {
  .root {
    padding-top: calc(84px + 32px);
    min-height: calc(100vh - 84px - 32px);
  }
  .loaderContainer {
    height: calc(100vh - 84px - 32px);
  }
}

@media (min-width: 1024px) {
  .root {
    min-height: auto;
  }
  .breadCrumbs {
    padding: 20px 42px;
    margin: 0 auto;
  }
  .title {
    margin: 0 auto;
    padding: 0 42px;
  }
  .restaurantsLayout {
    margin-top: 40px;
  }
}

@media (min-width: 1440px) {
  .restaurantsLayout {
    margin-top: 60px;
  }
}
