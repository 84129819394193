.root {
  padding: 8px 12px 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;
}
.details {
  color: var(--color-text-secondary);
}
