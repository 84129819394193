.ordering {
  padding-bottom: 88px;
}
.orderingContent {
  height: calc(85svh - 88px);
  overflow: scroll;
  -ms-overflow-style: none;
}
.orderingContent::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.inputs {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.input {
  padding: 0 16px;
}
.paymentTypeTitle {
  padding: 0 16px;
  margin-bottom: 16px;
}
.price {
  padding: 20px 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.priceItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.totalPrice {
  padding-top: 12px;
  border-top: 1px solid var(--color-divider-divider);
}
.submit {
  box-shadow: var(--elevation-2-dp);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px 16px 16px;
  box-sizing: border-box;
  background: var(--color-background-primary);
}
.bonusContainer {
  padding: 20px 16px 34px;
}

.header {
  padding: 16px 16px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.side {
  display: flex;
  align-items: center;
  gap: 16px;
}
.bonus {
  display: flex;
}

@media (min-width: 600px) {
  .ordering {
    padding-bottom: 92px;
  }
  .orderingContent {
    height: calc(85svh - 92px);
  }
  .input {
    padding: 0 20px;
  }
  .paymentTypeTitle {
    padding: 0 20px;
  }
  .price {
    padding: 20px 20px 24px;
  }
  .bonusContainer {
    padding: 20px 20px 34px;
  }
  .submit {
    padding: 24px 20px 20px;
    border-radius: 0 0 12px 12px;
  }
  .header {
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: flex-start;
  }
}
