.root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 4px);
  padding: 2px;
  background: var(--color-segmented-control-background);
  border-radius: 12px;
}

.segment {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  cursor: pointer;
  min-height: 32px;
  overflow: hidden;
  height: fit-content;
  border-radius: 10px;
  padding: 0 20px;
}
.segment > p {
  color: var(--color-text-secondary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.active {
  background: var(--color-segmented-control-active-segment);
}
.active > p {
  color: var(--color-text-primary);
}
