.root {
  width: 100%;
  height: calc(100vh - 118px - 32px);
  background: var(--color-background-primary);
  padding-top: calc(118px + 32px);
}
.container {
  margin: 0 auto;
  padding: 100px 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 320px;
}
.icon {
  width: 72px;
  height: 72px;
}
.title {
  padding-top: 16px;
  text-align: center;
}
.message {
  padding-top: 8px;
  text-align: center;
  color: var(--color-text-secondary);
}
.buttonsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
}
@media (min-width: 500px) {
  .root {
    height: calc(100vh - 84px - 32px);
    padding-top: calc(84px + 32px);
  }
  .container {
    padding-top: 176px !important;
  }
}
