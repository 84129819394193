.root {
  display: flex;
  flex-direction: column;
  height: calc(580px - 56px);
  max-height: calc(85svh - 56px);
}
.search {
  padding: 0 16px;
}
.listContainer {
  position: relative;
  overflow: scroll;
  height: calc(580px - 56px - 52px);
  max-height: calc(85svh - 56px - 52px);
  padding: 20px 16px;
}
.list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.listItem {
  cursor: pointer;
  padding-bottom: 12px;
}

.fetchingContainer {
  position: absolute;
  width: 100%;
  height: 80%;
  left: 0;
  top: 0;
  background: var(--color-background-primary);
  border-radius: 0px 0px 12px 12px;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
}
.placeholderContainer {
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 500px) {
  .root {
    width: 440px;
    height: calc(580px - 68px);
    max-height: calc(85svh - 68px);
  }
  .search {
    padding: 0 20px;
  }
  .listContainer {
    padding: 20px;
    height: calc(580px - 68px - 52px);
    max-height: calc(85svh - 68px - 52px);
  }
  .list {
    display: block !important;
    column-count: 2 !important;
    column-gap: 50px !important;
    padding-bottom: 8px !important;
  }
}
