.root {
  width: 100%;
  padding-bottom: 72px;
  height: calc(85svh - 72px);
  overflow: scroll;
  -ms-overflow-style: none;
}
.root::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.header {
  padding: 16px 16px 12px;
  display: flex;
  align-items: center;
  gap: 16px;
}
.heading {
  padding: 0 16px;
}
.data {
  padding: 18px 16px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.dataItem {
  position: relative;
  height: 36px;
}
.dataItem p:first-child {
  color: var(--color-text-secondary);
}
.dataItem p:last-child {
  position: absolute;
  top: 12px;
}
.cart {
  padding: 24px 16px 0;
}
.cartList {
  padding-top: 12px;
  display: flex;
  flex-direction: column;
}
.pricing {
  display: flex;
  flex-direction: column;
  padding: 0 16px 12px;
}
.pricingItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
}
.total {
  padding-bottom: 0 !important;
  padding-top: 12px;
  box-sizing: border-box;
  border-top: 1px solid var(--color-divider-divider);
}
.submit {
  box-sizing: border-box;
  width: 100%;
  padding: 12px 16px 12px;
  position: absolute;
  left: 0;
  bottom: 0;
  box-shadow: var(--elevation-2-dp);
  background: var(--color-background-primary);
}
.submitContent {
  display: flex;
  gap: 16px;
  padding-bottom: 16px;
}

.green {
  color: var(--color-text-completed);
}
.red {
  color: var(--color-text-canceled);
}
.orange {
  color: var(--color-text-waiting);
}

@media (min-width: 500px) {
  .root {
    width: 368px;
    height: calc(85svh - 72px);
    max-height: calc(628px - 72px);
  }
  .header {
    padding: 20px;
  }
  .heading {
    padding: 0 20px;
  }
  .data {
    padding: 18px 20px 0;
  }
  .cart {
    padding: 24px 20px 0;
  }
  .pricing {
    padding: 0 20px 12px;
  }
  .submit {
    padding: 12px 20px 12px;
    border-radius: 0 0 12px 12px;
  }
}
