.skeletonBannersList {
  overflow: hidden;
  display: flex;
  gap: 20px;
  max-width: 100%;
  flex-wrap: nowrap;
  z-index: 0 !important;
}
.skeletonBanner__story {
  width: 140px !important;
  height: 172px !important;
  border-radius: 12px;
}
.skeletonBanner__lastOrder {
  width: 280px !important;
  height: 172px !important;
}
.skeletonBanner {
  background: var(--color-button-secondary-default);
  border-radius: 12px;
  animation: shine 1.8s infinite linear;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.skeletonBanner:first-child {
  margin-left: 16px;
}
.skeletonBanner:last-child {
  margin-right: 16px;
}

.processingImage {
  width: 72px;
  height: 72px;
  animation: shine 1.8s infinite linear;
}

@keyframes shine {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 600px) {
  .skeletonBanner__lastOrder {
    width: 362px !important;
    height: 240px !important;
  }
  .skeletonBanner__story {
    width: 184px !important;
    height: 240px !important;
  }
  .skeletonBanner:first-child {
    margin-left: 42px;
  }
  .skeletonBanner:last-child {
    margin-right: 42px;
  }
}

@media (min-width: 1440px) {
  .skeletonBannersList {
    max-width: calc(1280px + 42px * 2);
    margin: 0 auto;
  }
}
