.root {
  width: 856px;
  height: 85svh;
  max-height: 600px;
  display: flex;
}
.constructorContainer {
  width: 504px;
  height: 100%;
}
.constructorHeader {
  padding: 20px 20px 12px;
}
.tabList {
  width: 504px;
  border-bottom: 1px solid var(--color-divider-divider);
  overflow: hidden;
}
.tabListContainer {
  width: calc(504px - 40px);
  margin-right: 20px;
  overflow: scroll;
  -ms-overflow-style: none;
  padding: 0 20px;
}
.tabListContainer::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.constructorList {
  height: calc(100% - 60px - 49px);
  overflow: scroll;
}
.listTitle {
  padding: 16px 20px;
}
.constructorListContent {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 0 20px 20px;
}
.listItem {
  width: 138px;
}
.infoContainer {
  width: 352px;
  height: 100%;
  /* TODO поменять цвет на цвет из либы */
  background: #fafafa;
  border-radius: 0 12px 12px 0;
  position: relative;
}
.infoHeader {
  padding: 20px;
}
.infoContent {
  height: calc(100% - 149px);
  /* height: calc(100% - 68px - 149px); */
  overflow: scroll;
}
.submit {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
}
.addToCartContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}
.addToCartButton {
  width: 184px;
}
.addToCartButtonContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.addToCartButtonContent,
.addToCartButtonContent > p {
  font-family: inherit !important;
  font-size: inherit !important;
  font-style: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
  letter-spacing: inherit !important;
  color: inherit !important;
}
.submitContainer {
  border-top: 1px solid var(--color-divider-divider);
}
.submitContent {
  padding: 16px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
