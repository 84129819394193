.root {
  height: 500px;
  position: relative;
  overflow: scroll;
  -ms-overflow-style: none;
}
.root::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.content {
  padding-bottom: 88px;
}
.name {
  padding: 20px 20px 0;
  margin-bottom: 8px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 20px;
  margin-bottom: 8px;
}
.headerContent {
  padding: 12px 0;
  border-bottom: 1px solid var(--color-divider-divider);
}
.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.address {
  color: var(--color-text-secondary);
}
.worktime {
  color: var(--color-text-tertiary);
}
.worktime__Closed {
  color: var(--color-text-secondary);
}
.distanceTo {
  color: var(--color-text-secondary);
}
.schedule {
  padding-bottom: 132px;
}
.scheduleTitle {
  margin-bottom: 16px;
  padding: 0 20px;
}
.scheduleItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.scheduleItem:not(:last-child) {
  margin-bottom: 16px;
}
.close {
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
}
