.root {
  width: 100%;
  height: calc(85svh - 56px);
  position: relative;
}
.nextButton {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 8px 16px;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid var(--color-divider-divider);
}
.tabList {
  width: 100%;
  border-bottom: 1px solid var(--color-divider-divider);
  overflow: hidden;
}
.tabListContainer {
  width: calc(100% - 32px);
  margin-right: 16px;
  overflow: scroll;
  -ms-overflow-style: none;
  padding: 0 16px;
}
.tabListContainer::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.constructorList {
  height: calc(85svh - 56px - 49px - 65px);
  overflow: scroll;
}
.listTitle {
  padding: 16px;
}
.constructorListContent {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 0 16px 16px;
}
.listItem {
  width: calc(100% / 2 - 10px);
}

.infoContainer {
  width: 100%;
  height: 90svh;
  position: relative;
}
.infoContent {
  height: calc(90svh - 65px);
  overflow-y: scroll;
}
.imageContainer {
  padding: 24px 0 20px;
}
.image {
  width: 100%;
  height: 320px;
  object-position: center;
  object-fit: contain;
}
.infoHeader {
  padding: 0 16px 12px;
}
.infoAddToCart {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid var(--color-divider-divider);
  background: var(--color-background-primary);
}
.infoAddToCartContainer {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.addToCartButton {
  width: 100%;
}
.addToCartButtonContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.addToCartButtonContent,
.addToCartButtonContent > p {
  font-family: inherit !important;
  font-size: inherit !important;
  font-style: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
  letter-spacing: inherit !important;
  color: inherit !important;
}

@media (min-width: 420px) {
  .listItem {
    width: calc(100% / 3 - 11px);
    max-width: 100%;
  }
}
@media (min-width: 560px) {
  .listItem {
    width: calc(100% / 4 - 12px);
  }
}
@media (min-width: 730px) {
  .listItem {
    width: calc(100% / 5 - 13px);
  }
}
