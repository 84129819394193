.container {
  position: relative;
  padding: 8px 0 11px;
  display: flex;
  align-items: flex-start;
}
.image {
  width: 56px;
  height: 56px;
  object-fit: contain;
  object-position: center;
}
.data {
  padding: 0 0 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
