.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  height: 66px;
}
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.inputContainer {
  position: relative;
  height: 34px;
  width: 18px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.inputContainer::after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  border-radius: 1px;
  background-color: var(--color-icon-primary);
}
.input {
  border: none;
  appearance: none;
  width: 14px;
  height: 28px;
  text-align: center;
  outline: none;
}
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.errorText {
  color: var(--color-text-error);
}
