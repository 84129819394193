.tabContainer {
  position: relative;
  display: flex;
  align-items: flex-start;
}
.tab {
  cursor: pointer;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab > p {
  white-space: nowrap;
}
.indicator {
  position: absolute;
  bottom: 0;
  height: 2px;
  left: 0;
  background: var(--color-background-secondary);
  transition: all 0.2s ease;
}
.activeTab {
  color: var(--color-text-tertiary);
}
.unactiveTab {
  color: var(--color-text-secondary);
}
.disabledTab {
  color: var(--color-text-disabled);
}

.activeTabBorder{
  border-bottom: 2px solid var(--color-text-tertiary);
}

.list {
  display: flex;
  gap: 20px;
  max-width: 100%;
  flex-wrap: nowrap;
  z-index: 0 !important;
}
