.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}
.dialog > * {
  user-select: none;
}

.wrapper {
  padding: 40px 8px 8px !important;
  overflow: hidden;
}
.extendedWrapperPadding {
  padding: 40px !important;
}
.container {
  position: relative;
  background: var(--color-background-primary);
  box-shadow: var(--elevation-16-dp) !important;
  border-radius: 12px;
  max-height: 85svh;
}
.closeButton {
  position: absolute;
  right: -32px;
  top: 0;
}

.containerEnter {
  opacity: 0;
}
.containerEnterActive {
  transition: opacity var(--animation-delay) ease-out;
  opacity: 1;
}
.containerExit {
  opacity: 1;
}
.containerExitActive {
  opacity: 0;
  transition: opacity var(--animation-delay) ease-out;
}
