.root {
  background: var(--color-button-fab-default);
  padding: 8px 12px;
  box-shadow: var(--elevation-6-dp);
  position: relative;
  display: block;
  border: none;
  text-align: center;
  border-radius: 12px;
  overflow: hidden;
  font-family: var(--headline-font-family) !important;
  font-size: var(--headline-font-size) !important;
  font-style: var(--headline-font-style) !important;
  font-weight: var(--headline-font-weight) !important;
  line-height: var(--headline-line-height) !important;
  letter-spacing: var(--headline-letter-spacing) !important;
}
.root:active {
  background: var(--color-button-fab-ripple);
}
