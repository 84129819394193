.list {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 52px;
  width: 100%;
  background: var(--color-background-primary);
  border-top: 1px solid var(--color-field-inactive);
  border-radius: 0px 0px 12px 12px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14), 0px 3px 5px rgba(0, 0, 0, 0.2);
}
.listContent {
  display: flex;
  flex-direction: column;
}
.disabled {
  opacity: 0.5;
}
