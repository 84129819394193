.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}
.container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: var(--color-placeholder-placeholder);
}
.content {
  position: relative;
  width: 100%;
  user-select: none;
  height: 100%;
}
.image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  user-select: none;
}
.closeButton {
  position: absolute;
  right: 16px;
  top: 38px;
}
.timeLineContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}
.timeLine {
  height: 2px;
  background: #ffffff50;
  border-radius: 2px;
  flex: 1;
  position: relative;
}
.timeLineIndicator {
  position: absolute;
  left: 0;
  top: 0;
  height: 2px;
  background: #ffffff;
  border-radius: 2px;
  width: 0;
}

.timeLineIndicator__full {
  width: 100%;
}
.buttonContainer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.linkButton {
  position: relative;
  display: block;
  border: none;
  text-align: center;
  box-shadow: none;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  padding: 12px 16px;
  border-radius: 12px;
  font-family: var(--headline-font-family) !important;
  font-size: var(--headline-font-size) !important;
  font-style: var(--headline-font-style) !important;
  font-weight: var(--headline-font-weight) !important;
  line-height: var(--headline-line-height) !important;
  letter-spacing: var(--headline-letter-spacing) !important;
}

.spinnerContainer {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-placeholder-placeholder);
  transition: opacity var(--animation-delay) linear;
}
.spinnerContainer_loading {
  opacity: 0;
}

.skipContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  background: none;
  display: flex;
}
.skipItem {
  height: 100%;
  width: 50%;
}

@media (min-width: 500px) {
  .container {
    position: relative;
    box-shadow: var(--elevation-16-dp) !important;
    border-radius: 12px;
    height: 85svh;
    max-height: 616px;
    width: 368px;
  }
  .closeButton {
    right: -32px;
    top: 0;
  }
  .image,
  .spinnerContainer {
    border-radius: 12px;
  }
  .skipItem__prev {
    position: absolute;
    top: 0;
    width: calc(50% + 24px + 8px);
    left: calc(-24px - 8px);
  }
  .skipItem__next {
    position: absolute;
    top: 0;
    width: calc(50% + 24px + 8px);
    right: calc(-24px - 8px);
  }
  .skipButton__prev {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%) rotate(180deg);
    background-image: var(--img-ic-24-chevron-right-on-color);
  }
  .skipButton__next {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    background-image: var(--img-ic-24-chevron-right-on-color);
  }
}

.containerEnter {
  opacity: 0;
}
.containerEnterActive {
  transition: opacity var(--animation-delay) ease-out;
  opacity: 1;
}
.containerExit {
  opacity: 1;
}
.containerExitActive {
  opacity: 0;
  transition: opacity var(--animation-delay) ease-out;
}
