.container {
  position: relative;
  min-height: 60px;
}

.text {
  padding-right: 68px;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--color-text-on-color);
}

.message {
  margin-top: 8px;
}

.codeContainer {
  width: fit-content;
  height: fit-content;
  position: relative;
  background: var(--color-background-primary);
  padding: 7px;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}
