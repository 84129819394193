.button {
  background-image: var(--img-ic-24-copy);
  width: 16px;
  height: 16px;
  background-position: center;
  background-size: 16px 16px;
}

.dialog {
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}
.container {
  border-radius: 12px;
  padding: 12px 16px;
  background: var(--color-background-info);
}
.content {
  display: flex;
  align-items: center;
  gap: 24px;
}
.message {
  color: var(--color-text-info);
}
.closeButton {
  background-image: var(--img-ic-24-close-oncolor);
}

.containerEnter {
  opacity: 0;
}
.containerEnterActive {
  transition: opacity var(--animation-delay) ease-out;
  opacity: 1;
}
.containerExit {
  opacity: 1;
}
.containerExitActive {
  opacity: 0;
  transition: opacity var(--animation-delay) ease-out;
}
