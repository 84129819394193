.list {
  position: absolute;
  z-index: 1;
  left: 0;
  /* top: 52px; */
  width: 100%;
  background: var(--color-background-primary);
  border-radius: 0px 0px 12px 12px;
  height: fit-content;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14), 0px 3px 5px rgba(0, 0, 0, 0.2);
}
.line {
  position: absolute;
  z-index: 1;
  left: 0;
  top: -2px;
  background: var(--color-background-primary);
  width: 100%;
  height: 3px;
  pointer-events: none;
}
.fetching {
  background: var(--color-background-primary);
  border-radius: 0px 0px 12px 12px;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.placeholder {
  background: var(--color-background-primary);
  border-radius: 0px 0px 12px 12px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.listContent {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  -ms-overflow-style: none;
  max-height: calc(600px - 64px - 60px - 60px - 52px - 24px);
  height: calc(85svh - 64px - 60px - 60px - 52px - 24px);
}
.listContent::-webkit-scrollbar {
  width: 0;
}

/* @media (min-width: 500px) {
  .listContent {
    max-height: 400px;
  }
} */
