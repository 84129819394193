.root {
  display: flex;
  flex-direction: column;
}
.label {
  padding-bottom: 12px;
}
.radio {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;
  background-color: #ffffff;

  border: 2px solid var(--color-button-primary-default);
  border-radius: 50%;
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;

  background-color: var(--color-button-primary-default);
  opacity: 0;
}

.input {
  display: none;
}
.input:checked + span .dot {
  opacity: 1;
}

.inputContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}
.inputs {
  max-width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
