.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.conditions {
  padding: 0 16px;
  display: flex;
  flex-direction: column;
}
.condition {
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.condition:not(:last-child) {
  padding: 12px 0 11px;
  border-bottom: 1px solid var(--color-divider-divider);
}
.submit {
  padding: 20px 16px 16px;
}
@media (min-width: 500px) {
  .root {
    width: 368px;
  }
  .conditions {
    padding: 0 20px;
  }
  .submit {
    padding: 20px;
  }
}
