.list {
  width: 100%;
}

.slide {
  width: fit-content;
}

.slide:first-child {
  margin-left: 16px;
}
.slide:last-child {
  margin-right: 16px;
}

@media (min-width: 500px) {
  .slide:first-child {
    margin-left: 20px;
  }
  .slide:last-child {
    margin-right: 20px;
  }
}
