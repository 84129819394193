.root {
  position: fixed;
  top: 32px;
  left: 0;
  width: 100%;
  background: var(--color-background-primary);
  z-index: 11;
}

.logo {
  height: 36px;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}
.header {
  padding: 6px 16px 8px;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.menuSide {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  position: relative;
  right: 12px;
  width: 82px;
  height: 43px;
}
.dataSide {
  display: flex;
  position: sticky;
  align-items: center;
  z-index: 10;
}
.dataSidePadding {
  top: 0;
  transition: 0.2s;
}

.dataSideNoPadding {
  top: -1px;
  transition: 0.2s;
}

.headerButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;

}

.addressContainer > div > div > * {
  color: white !important;
}

.addressContainer {
  width: 100%;
  position: relative;
  z-index: 10;
}
.balanceContainer {
  padding-left: 12px;
  width: fit-content;
  min-width: 66px;
}
@media (min-width: 600px) {
  .addressContainer {
    max-width: fit-content;
    width: 100%;
  }
  .balanceContainer {
    padding-left: 0;
  }
  .header {
    padding: 20px 42px 0;
  }
  .container {
    border-bottom: 1px solid var(--color-divider-divider);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1196px;
    margin: 0 auto;
    padding-bottom: 13px;
  }
  .appData {
    display: flex;
    align-items: center;
    gap: 20px;
    max-width: 60%;
  }
  .userData {
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
  }
}
