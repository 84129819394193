.root {
  width: 100%;
  position: relative;
}
.container {
  height: calc(85svh - 88px - 24px);
  max-height: calc(778px - 88px - 24px);
  /* max-height: calc(85svh - 56px - 88px - 24px); */
  padding-bottom: calc(88px + 24px);
  -ms-overflow-style: none;
  overflow: scroll;
}
.container::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.header {
  padding: 0 16px;
  box-sizing: border-box;
  width: 100%;
}
.headerContent {
  background: var(--color-button-secondary-default);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 8px;
}
.headerMessage {
  text-align: center;
}
.avatar {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.name {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 16px;
}
.gender {
  padding: 12px 16px;
}
.fields {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 16px;
}
.submit {
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  background: var(--color-background-primary);
  box-shadow: var(--elevation-2-dp);
  padding: 24px 16px 16px;
  border-radius: 0 0 12px 12px;
}

@media (min-width: 500px) {
  .root {
    width: 368px;
  }
  .container {
    height: calc(85svh - 92px - 24px);
    max-height: calc(778px - 92px - 24px);
    padding-bottom: calc(92px + 24px);
  }
  .header {
    padding: 0 20px;
  }
  .avatar {
    padding: 16px 20px;
  }
  .name {
    padding: 0 20px;
  }
  .gender {
    padding: 12px 20px;
  }
  .fields {
    padding: 0 20px;
  }
  .submit {
    padding: 24px 20px 20px;
  }
}
