.header {
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fullPrice {
  color: var(--color-text-secondary);
  transform: scale(-50%);
}
.data {
  padding: 18px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.dataItem {
  position: relative;
  height: 36px;
}
.dataItem p:first-child {
  color: var(--color-text-secondary);
}
.dataItem p:last-child {
  position: absolute;
  top: 12px;
}
.gallery {
  width: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
}
.gallery::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.galleryContent {
  display: flex;
  gap: 12px;
  padding-bottom: 16px;
}
.galleryImage {
  width: 56px;
  height: 56px;
  object-fit: contain;
  object-position: center;
}
.galleryImage:first-child {
  padding-left: 16px;
}
.galleryImage:last-child {
  padding-right: 16px;
}
.submit {
  padding: 0 16px;
}
.submitContainer {
  border-bottom: 1px solid var(--color-divider-divider);
}
.order:last-child .SubmitContainer {
  border-bottom: none;
}
.submitContent {
  display: flex;
  gap: 16px;
  padding-bottom: 16px;
}

.green {
  color: var(--color-text-completed);
}
.red {
  color: var(--color-text-canceled);
}
.orange {
  color: var(--color-text-waiting);
}

@media (min-width: 500px) {
  .root {
    width: 368px;
    height: calc(85svh - 68px);
  }
  .header {
    padding: 0 20px;
  }
  .data {
    padding: 18px 20px 16px;
  }
  .galleryImage:first-child {
    padding-left: 20px;
  }
  .galleryImage:last-child {
    padding-right: 20px;
  }
  .submit {
    padding: 0 20px;
  }
}
