.footer {
  background: var(--color-background-footer);
  position: relative;
  z-index: 11;
}
.wrapper {
  margin: 0 auto;
  max-width: 1196px;
}
.dataContainer {
  padding: 40px 16px 0;
  display: flex;
  justify-content: space-between;
}
.dataItem {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-direction: column;
}
.dataValue {
  color: var(--color-text-secondary);
  text-decoration: none;
}

.accountsContainer {
  margin-top: 24px;
  padding: 0 16px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}
.accountButton {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background: var(--color-background-primary);
}

.information {
  margin-top: 40px;
  padding: 0 16px;
}
.informationList {
  margin-top: 12px;
  display: grid;
  grid-template-columns: repeat(2, calc(50%));
  row-gap: 12px;
}
.informationListItem {
  color: var(--color-text-secondary);
  cursor: pointer;
  text-decoration: none;
}

.apps {
  margin-top: 40px;
  padding: 0 16px;
}
.appsList {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}
.appLink {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  background: none;
  overflow: hidden;
  padding: 0;
  margin: 0;
  height: min-content;
  width: min-content;
}

.legalInformation {
  margin-top: 24px;
  padding: 0 16px;
}
.legalInformationWrapper {
  border-top: 1px solid var(--color-divider-divider);
  display: flex;
  justify-content: space-between;
  padding-top: 28px;
}
.legalInformationContainer {
  display: flex;
  flex-direction: column;
}
.legalInformationValue {
  color: var(--color-text-secondary);
}
.legalInformationLink {
  display: block;
  padding: 16px 0 20px;
  color: var(--color-text-secondary);
  text-decoration: none;
}

.mediaLinks {
  display: flex;
  column-gap: 12px;
}

.informationAndDataContainer {
  display: flex;
  margin-bottom: 20px;
}

@media (min-width: 500px) {
  .dataContainer {
    flex-direction: column;
    gap: 24px;
    justify-content: start;
  }

  .informationList {
    grid-template-columns: repeat(1, 160px);
  }

  .legalInformationContainer {
    padding-left: 16px;
  }

  .apps {
    margin: 0;
    padding: 0;
  }

  .legalInformationWrapper {
    flex-direction: column;
    gap: 24px;
  }

  .accountsContainer {
    margin: 0;
    padding: 0;
  }

  .legalInformation {

  }

}

@media (min-width: 300px) {
  .dataContainer {
    flex-direction: column;
    gap: 24px;
    justify-content: start;
  }

  .informationList {
    grid-template-columns: repeat(1, 160px);
  }

  .legalInformationContainer {
    padding-left: 16px;
  }

  .informationAndDataContainer {
    flex-direction: column;
  }

  .information {
    margin-top: 24px;
  }

  .apps {
    margin: 0;
    padding: 0;
  }

  .legalInformationWrapper {
    flex-direction: column;
    gap: 24px;
  }

  .accountsContainer {
    margin: 0;
    padding: 0;
  }

  .legalInformation {

  }

}

@media (min-width: 1024px) {
  .mainDataWrapper {
    padding: 40px 0 0;
    display: flex;
    justify-content: space-between;
    gap: 75px;
  }
  .dataContainer,
  .dataItems {
    display: flex;
    padding: 0;
    flex-direction: column;
    justify-content: flex-start;
    gap: 24px;
  }
  .information {
    margin: 0;
    padding: 0;
  }
  .informationList {
    grid-template-columns: repeat(1, 160px);
  }
  .apps {
    margin: 0;
    padding: 0;
  }
  .accountsContainer {
    padding: 0;
    margin: 0;
  }
  .legalInformation {
    padding: 0 0 20px;
  }
  .legalInformationContainer {
    display: flex;
    align-items: flex-start;
    gap: 24px;
  }
  .legalInformationLink {
    padding: 0;
  }

  .legalInformationWrapper {
    flex-direction: row;
  }
}

@media (max-width: 1440px) and (min-width: 1024px) {
  .mainDataWrapper {
    padding: 40px 0 0;
  }
  .wrapper {
    padding-right: 34px;
    padding-left: 34px;
  }

}

@media (min-width: 1440px) {
  .wrapper {
    max-width: 1196px;
  }
  .mainDataWrapper {
    padding: 42px 0 0;
  }
  .dataItems {
    flex-direction: column;
    gap: 24px;
    flex-wrap: nowrap;
  }
  .dataItem {
    width: fit-content;
  }
  .dataValue {
    white-space: nowrap;
  }
  .legalInformation {
    padding: 0 0 20px;
  }
}
