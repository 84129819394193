.root {
  width: 100%;
  position: relative;
}

@media (min-width: 600px) {
  .root {
    width: 500px;
    transition: width var(--animation-delay) linear;
  }
  .root__ordering {
    width: 368px;
  }
}
