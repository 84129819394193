.root {
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  cursor: pointer;
}
.root:first-child {
  padding-top: 8px;
}
.root:last-child {
  padding-bottom: 8px;
}
.container {
  padding: 8px 0 8px;
}
.root:not(:first-child) > .container {
  border-top: 1px solid var(--color-divider-divider);
}
.info {
  position: relative;
  width: 100%;
}
.distanceTo {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--color-text-secondary);
}
.address {
  padding-top: 4px;
  color: var(--color-text-secondary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.worktime {
  color: var(--color-text-tertiary);
}
.worktime__closed {
  color: var(--color-text-secondary);
}
@media (min-width: 500px) {
  .root {
    padding: 0px 20px;
  }
}
