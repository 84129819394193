:root {
  /**----------- COLORS -----------**/
  --color-product-color-primary: rgba(255, 204, 0, 1);
  --color-product-color-primary-variant: rgba(255, 212, 38, 1);
  --color-product-color-secondary: rgba(245, 245, 245, 1);
  --color-product-color-secondary-variant: rgba(8, 8, 8, 1);
  --color-background-primary: rgba(255, 255, 255, 1);
  --color-background-secondary: rgba(243, 43, 43, 1);
  --color-background-tertiary: rgba(245, 245, 245, 1);
  --color-background-overlay: rgba(8, 8, 8, 0.4);
  --color-background-secondary: rgba(255, 204, 0, 1);
  --color-background-info: rgba(51, 51, 51, 1);
  --color-background-order: rgba(246, 246, 246, 1);
  --color-background-delete: rgba(244, 78, 78, 1);
  --color-background-overlay-white: rgba(255, 255, 255, 0.5);
  --color-background-footer: rgba(248, 248, 248, 1);
  --color-banner-warning: rgba(255, 246, 212, 1);
  --color-button-primary-default: rgba(255, 204, 0, 1);
  --color-button-primary-disable: rgba(255, 204, 0, 0.4);
  --color-button-primary-ripple: rgba(255, 221, 85, 1);
  --color-button-primary-pressed: rgba(237, 203, 65, 1);
  --color-button-secondary-default: rgba(245, 245, 245, 1);
  --color-button-secondary-disable: rgba(245, 245, 245, 0.4);
  --color-button-secondary-ripple: rgba(237, 237, 237, 1);
  --color-button-secondary-pressed: rgba(229, 229, 229, 1);
  --color-button-fab-default: rgba(255, 255, 255, 1);
  --color-button-fab-ripple: rgba(245, 245, 245, 1);
  --color-button-fab-pressed: rgba(245, 245, 245, 1);
  --color-tab-active: rgba(255, 204, 0, 1);
  --color-tab-inactive: rgba(255, 255, 255, 1);
  --color-tab-disable: rgba(245, 245, 245, 1);
  --color-chat-message-background-guest: rgba(255, 204, 0, 1);
  --color-chat-message-background-support: rgba(245, 245, 245, 1);
  --color-divider-divider: rgba(229, 229, 229, 1);
  --color-field-inactive: rgba(212, 212, 212, 1);
  --color-field-activated: rgba(179, 177, 177, 1);
  --color-field-error: rgba(247, 99, 105, 1);
  --color-icon-primary: rgba(255, 186, 0, 1);
  --color-icon-secondary: rgba(8, 8, 8, 1);
  --color-icon-tertiary: rgba(156, 160, 163, 1);
  --color-icon-on-color: rgba(8, 8, 8, 1);
  --color-icon-on-color-disabled: rgba(156, 156, 156, 1);
  --color-icon-disabled: rgba(255, 227, 153, 1);
  --color-icon-on-delete: rgba(255, 255, 255, 1);
  --color-placeholder-placeholder: rgba(212, 211, 219, 1);
  --color-progress-indicator-primary: rgba(255, 186, 0, 1);
  --color-progress-indicator-on-color: rgba(0, 0, 0, 1);
  --color-progress-indicator-background: rgba(255, 255, 255, 0.6);
  --color-segmented-control-active-segment: rgba(255, 255, 255, 1);
  --color-segmented-control-background: rgba(229, 229, 229, 1);
  --color-slider-active: rgba(255, 186, 0, 1);
  --color-slider-inactive: rgba(255, 230, 163, 1);
  --color-switch-thumb-enable-on: rgba(255, 204, 0, 1);
  --color-switch-thumb-enable-off: rgba(255, 255, 255, 1);
  --color-switch-track-enable-on: rgba(255, 240, 179, 1);
  --color-switch-track-enable-off: rgba(235, 235, 240, 1);
  --color-text-primary: rgba(8, 8, 8, 1);
  --color-text-secondary: rgba(107, 107, 107, 1);
  --color-text-tertiary: rgba(255, 186, 0, 1);
  --color-text-disabled: rgba(156, 156, 156, 1);
  --color-text-on-color: rgba(8, 8, 8, 1);
  --color-text-on-color-disabled: rgba(8, 8, 8, 0.4);
  --color-text-error: rgba(224, 49, 55, 1);
  --color-text-warning: rgba(0, 0, 0, 1);
  --color-text-positive: rgba(113, 209, 84, 1);
  --color-text-canceled: rgba(224, 49, 55, 1);
  --color-text-waiting: rgba(255, 186, 0, 1);
  --color-text-completed: rgba(113, 209, 84, 1);
  --color-text-info: rgba(255, 255, 255, 1);
  --color-text-subtraction: rgba(224, 49, 55, 1);
  --color-zone-fast: rgba(55, 173, 0, 1);
  --color-zone-slow: rgba(177, 0, 0, 1);
}
