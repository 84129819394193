.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
}

.wrapper {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 40px 8px 8px !important;
  overflow: hidden;
}
.container {
  background: var(--color-background-primary);
  box-shadow: var(--elevation-16-dp) !important;
  border-radius: 12px;
  max-height: 85svh;
}

.card {
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-width: 500px) {
  .dialog {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wrapper {
    width: fit-content;
    position: relative;
  }
  .card {
    max-width: 368px;
    min-width: 304px;
    width: 368px;
  }
}
.image {
  padding: 16px;
}
.image > img {
  width: 72px;
  height: 72px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.title {
  text-align: center !important;
}
.description {
  text-align: center !important;
  color: var(--color-text-secondary);
  white-space: pre-wrap;
}
.submit {
  padding-top: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.containerEnter {
  opacity: 0;
}
.containerEnterActive {
  transition: opacity var(--animation-delay) ease-out;
  opacity: 1;
}
.containerExit {
  opacity: 1;
}
.containerExitActive {
  opacity: 0;
  transition: opacity var(--animation-delay) ease-out;
}
