.root {
  display: inline-block;
  animation: animation 1s linear infinite;
  border-bottom-color: transparent !important;
  border-left-color: transparent !important;
}
.primary {
  border-color: var(--color-progress-indicator-primary);
}
.secondary {
  border-color: var(--color-progress-indicator-primary);
}
.large {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.largePrimary {
  border: 4px solid var(--color-progress-indicator-primary);
}
.largeSecondary {
  border: 4px solid var(--color-progress-indicator-on-color);
}
.medium {
  width: 23px;
  height: 23px;
  border-radius: 50%;
}
.mediumPrimary {
  border: 2.5px solid var(--color-progress-indicator-primary);
}
.mediumSecondary {
  border: 2.5px solid var(--color-progress-indicator-on-color);
}
.small {
  width: calc(18px - 3.2px);
  height: calc(18px - 3.2px);
  border-radius: 50%;
}
.smallPrimary {
  border: 1.6px solid var(--color-progress-indicator-primary);
}
.smallSecondary {
  border: 1.6px solid var(--color-progress-indicator-on-color);
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.containerSmall {
  width: 24px;
  height: 24px;
}
.containerMedium {
  height: 32px;
  width: 32px;
}
.containerLarge {
  height: 48px;
  width: 48px;
}

@keyframes animation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
