.banners {
  position: static;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}
.banners::after,
.banners::before {
  height: 172px;
  width: 16px;
  bottom: 0;
  content: '';
  position: absolute;
  z-index: 1;
}

.list {
  display: flex;
  gap: 20px;

  flex-wrap: nowrap;
  z-index: 0 !important;
  max-width: calc(948px);
}
.list > .swiper-button-prev,
.list > .swiper-button-next {
  background-color: var(--color-background-primary) !important;
  width: 48px !important;
  height: 48px !important;
  border-radius: 24px !important;
  box-shadow: var(--elevation-4-dp) !important;
}
.banner {
  box-sizing: border-box;
  flex-shrink: 0;
  cursor: pointer;
  overflow: hidden;
  border-radius: 12px;
}
.banner__story {
  width: 140px !important;
  height: 172px !important;
  border-radius: 12px;
  background: var(--color-button-secondary-default);
}
.banner__lastOrder {
  width: 280px !important;
  height: 172px !important;
}
.banner__giftRepurchase {
  width: 280px !important;
  height: 172px !important;
}
.banner:first-child {
  /*margin-left: 16px !important;*/
}
.banner:last-child {
  margin-right: 16px !important;
}

@media (min-width: 600px) {
  .banners {
    padding: calc(74px + 4px + 32px) 0 0px;
  }
  .banners::after,
  .banners::before {
    height: 240px;
    width: 42px;
  }
  .banner__lastOrder {
    width: 362px !important;
    height: 240px !important;
  }
  .banner__giftRepurchase {
    width: 362px !important;
    height: 240px !important;
  }
  .banner__story {
    width: 184px !important;
    height: 240px !important;
  }
  .banner:first-child {
    /*margin-left: 42px !important;*/
  }
  .banner:last-child {
    margin-right: 42px !important;
  }
}

@media (min-width: 1440px) {
  .list {
    max-width: calc(948px);;
  }
  .banners::after {
    right: calc((100% - 1280px) / 2 - 42px);
  }
  .banners::before {
    left: calc((100% - 1280px) / 2 - 42px);
  }
}
