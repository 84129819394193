.root {
  cursor: pointer;
  border-radius: 12px;
  box-shadow: var(--elevation-4-dp);
}
.root__active {
  border: 2px solid var(--color-button-primary-default);
}
.root__disabled {
  opacity: 0.5;
}
.imageContainer {
  padding: 2px;
}
.imageContainer__active {
  padding: 0;
}
.image {
  width: 100%;
  object-position: center;
  object-fit: contain;
  max-height: 150px;
}
.data {
  box-sizing: border-box;
  height: 86px;
  padding: 0 8px 8px;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.data__active {
  padding: 0 6px 6px;
}
