.rootContainer {
  max-height: 600px;
  height: 85svh;
  overflow: scroll;
  -ms-overflow-style: none;
}
.rootContainer::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.root {
  padding-bottom: 148px;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 16px;
  margin-bottom: 18px;
}
.headerContent {
  padding: 12px 0;
  border-bottom: 1px solid var(--color-divider-divider);
}
.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.address {
  color: var(--color-text-secondary);
}
.worktime {
  color: var(--color-text-tertiary);
}
.worktime__Closed {
  color: var(--color-text-secondary);
}
.distanceTo {
  color: var(--color-text-secondary);
}
.categories {
  display: flex;
  flex-direction: column;
}
.categoriesHeader {
  padding: 16px 16px 8px;
}
.categoriesGallery {
  display: flex;
  max-width: 100% !important;
  gap: 12px;
  padding-bottom: 16px;
  z-index: 0 !important;
}
.orderTypes {
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.category {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: fit-content !important;
}
.category:first-child {
  padding-left: 16px;
}
.category:last-child {
  padding-right: 16px;
}
.categoryImage {
  width: 80px;
  height: 80px;
  object-fit: cover;
  object-position: center;
}
.categoryTitle {
  text-align: center;
}
.scheduleContainer {
  border-top: 1px solid var(--color-divider-divider);
}
.scheduleContainer__closed {
  padding-bottom: 84px;
}
.scheduleContent {
  padding: 0 16px;
  /* height: 113px; */
  /* overflow: scroll; */
}
.scheduleContent__closed {
  /* height: calc(150px + 48px); */
}
.scheduleHeader {
  padding: 15px 0 8px;
}
.button__chevron__openChedule {
  transform: rotate(180deg);
}
.scheduleListHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0;
  cursor: pointer;
}
.scheduleListContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.scheduleListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.scheduleListItem:last-child {
  padding-bottom: 8px;
}
.scheduleListItem p {
  color: var(--color-text-secondary);
}
.submit {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 16px 16px 20px;
  background: var(--color-background-primary);
  box-shadow: var(--elevation-2-dp);
  overflow: hidden;
}
@media (min-width: 500px) {
  .rootContainer {
    width: 428px;
  }
  .header {
    padding: 0 20px;
  }
  .categoriesHeader {
    padding: 16px 20px 8px;
  }
  .category:first-child {
    padding-left: 20px;
  }
  .category:last-child {
    padding-right: 20px;
  }
  .scheduleContent {
    padding: 0 20px;
  }
  .submit {
    padding: 16px 20px 20px;
    border-radius: 0 0 12px 12px;
  }
}
