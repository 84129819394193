.root {
  position: relative;
  /* width: fit-content;
  height: fit-content; */
}
.loaderContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: var(--color-background-primary);
  transition: opacity var(--animation-delay) linear;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loaderContainer__loaded {
  opacity: 0;
}
.image {
  object-position: center;
}
.video {
  object-position: center;
}
