.container {
  position: relative;
  width: 100%;
  max-height: calc(85svh);
  height: calc(504px);
  position: relative;
  overflow-y: scroll;
  -ms-overflow-style: none;
}
.container::-webkit-scrollbar {
  width: 0;
  height: 0;
}
@media (min-width: 500px) {
  .container {
    width: 368px;
    /* height: calc(85svh - 68px); */
    max-height: calc(85svh);
    height: calc(504px);
  }
}
