.root {}

.logo {
  height: 36px;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}

.header {
  position: fixed;
  padding-top: 34px;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--color-background-primary);
  z-index: 12;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.menuSide {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0;
}

.dataSide {
  padding: calc(32px + 62px + 8px) 16px 8px;
  display: flex;
  align-items: center;
  padding-top: 94px;
}

.headerButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
}

.addressContainer {
  width: 100%;
  position: relative;
}

.balanceContainer {
  padding-left: 12px;
  width: fit-content;
  min-width: 66px;
}

@media (max-width: 1024px) and (min-width: 500px) {
  .root {
    position: sticky;
    top: 0;
  }
}

@media (min-width: 1024px) {
  .container {
    padding: 10px 34px;
  }
}


@media (min-width: 600px) {
  .addressContainer {
    max-width: fit-content;
    width: 100%;
  }

  .balanceContainer {
    padding-left: 0;
  }

  .header {}

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1196px;
    margin: 0 auto;
    padding-bottom: 13px;
  }

  .appData {
    display: flex;
    align-items: center;
    gap: 20px;
    max-width: 60%;
  }

  .userData {
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
  }
}

@media (min-width: 500px) and (max-width: 1024px) {
  .appData {
    display: block;
    flex-direction: column;
    max-width: 100%;
  }

  .headerButtonsContainer {
    position: absolute;
    display: flex;
    gap: 16px;
    right: 16px;
    top: 12px;
  }

  .container {
    display: block;
    flex-direction: unset;
    align-items: unset;
    max-width: 100%;
    justify-content: unset;
    width: 100%;
  }

  .addressContainer {
    max-width: calc(100% - 16px);
    margin-top: 12px;

  }

  .header {
    padding: 0;
    position: sticky;
    top: 0;
    background: var(--color-background-primary);
    padding-left: 16px;
    padding-top: 12px;
    width: calc(100% - 16px);
  }


}