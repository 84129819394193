.root {
  width: 280px;
  height: 172px;
  background: var(--color-background-order);
  border-radius: 12px;
  position: relative;
}
.image {
  width: 280px;
  height: 172px;
  border-radius: 12px;
  object-fit: cover;
  object-position: center;
}
.loader {
  background-color: var(--color-button-secondary-default);
}
.processingImage {
  width: 72px;
  height: 72px;
  animation: shine 1.8s infinite linear;
}
.progressContainer {
  width: 190px;
  pointer-events: none;
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.progressCaption {
  margin-bottom: 8px;
}

@keyframes shine {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 600px) {
  .root,
  .image {
    width: 362px !important;
    height: 240px !important;
  }
}
