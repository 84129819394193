.bottomSheet {
  /* position: absolute;
  bottom: 0;
  left: 0; */
  width: 100%;
  margin-top: -61px;
  overflow: hidden;
  border-bottom: none;
  transition: border-bottom var(--animation-delay) linear;
}
.bottomSheet__divider {
  border-bottom: 1px solid var(--color-divider-divider);
}
.wrapper {
  position: relative;
  width: 100%;
  background: var(--color-background-primary);
  border-radius: 24px 24px 0px 0px;
  box-shadow: var(--elevation-16-dp) !important;
  /* height: 300px;
  max-height: calc(70svh); */
  /* max-height: calc(100svh - 118px - 100px);
  overflow: scroll;
  -ms-overflow-style: none; */
}
/* .wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
} */
.header {
  padding: 20px 20px 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.colorTitle {
  border-radius: 50%;
  width: 12px;
  height: 12px;
}
.infoItem {
  padding: 20px 20px 0;
}
.infoItem:last-child {
  padding-bottom: 20px;
}
.infoItemContainer {
  padding: 20px;
  /* TODO: поменять цвет на цвет из либы */
  background: #f8f8f8;
  border-radius: 12px;
}
.infoItemContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.infoItemLabel {
  margin-bottom: 16px;
}
.deliveryPriceList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.deliveryPriceItem {
  width: 100%;
}
.deliveryPriceItem:not(:last-child) {
  border-bottom: 1px solid var(--color-divider-divider);
}
.deliveryPriceItemContainer {
  padding: 12px 0;
}
.deliveryPriceItem:last-child > .deliveryPriceItemContainer {
  padding: 11px 0 12px;
}
.deliveryPriceItemContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.containerEnter {
  opacity: 0;
}
.containerEnterActive {
  transition: opacity var(--animation-delay) ease-out;
  opacity: 1;
}
.containerExit {
  opacity: 1;
}
.containerExitActive {
  opacity: 0;
  transition: opacity var(--animation-delay) ease-out;
}

.containerEnter {
  max-height: 0 !important;
}
.containerEnterActive {
  transition: max-height var(--animation-delay) linear;
  max-height: calc(100svh - 118px - 100px) !important;
}
.containerExit {
  max-height: calc(100svh - 118px - 100px) !important;
}
.containerExitActive {
  transition: max-height var(--animation-delay) ease-out;
  max-height: 0 !important;
}
