.infoButton {
  background-image: var(--img-ic-24-help);
  position: absolute;
  top: 18px;
  right: 16px;
}
.info {
  width: 100%;
}
.infoContainer {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.infoIcon {
  width: 224px;
  height: 200px;
  object-fit: contain;
  object-position: center;
}
.infoContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.infoTitle {
  text-align: center;
}
.infoMessage {
  text-align: center;
  padding: 8px 24px 36px;
  color: var(--color-text-secondary);
}
.transactions {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.noTransactionsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: calc(85svh - 56px - 12px);
  height: calc(504px - 56px - 12px);
}
.noTransactionsMessage {
  padding: 0 16px;
  color: var(--color-text-secondary);
}
.dateTransactionContainer {
  padding: 0 16px;
}
.dateTransactionDate {
  color: var(--color-text-secondary);
}
.dateTransactions {
  padding-top: 12px;
  display: flex;
  flex-direction: column;
}

@media (min-width: 500px) {
  .infoButton {
    top: 22px;
    right: 20px;
  }
  .noTransactionsContainer {
    max-height: calc(85svh - 68px - 12px);
    height: calc(504px - 68px - 12px);
  }
  .noTransactionsMessage {
    padding: 0 20px;
  }
  .dateTransactionContainer {
    padding: 0 20px;
  }
}
