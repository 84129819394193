.root {
  background: var(--color-background-primary);
  position: relative;
  border-radius: 12px;
  cursor: text;
  padding: 7px 11px;
  border: 1px solid var(--color-field-activated);
}
.root__error {
  padding: 6px 10px;
  border: 2px solid var(--color-field-error);
}
.root__disabled {
  border: 1px solid var(--color-field-inactive);
}
.container {
  display: flex;
  align-items: center;
  position: relative;
}
.textField {
  position: relative;
  padding: 12px 26px 0 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
}
.textField__input {
  resize: none;
  text-decoration: inherit;
  text-transform: inherit;
  align-self: flex-end;
  box-sizing: border-box;
  padding: 0;
  width: 100%;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  border: none;
  border-radius: 0;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}
.textField__input::-webkit-outer-spin-button,
.textField__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.textField__disabled {
  color: var(--color-text-disabled);
}
.floatingLabel {
  color: var(--color-text-secondary);
  position: absolute;
  left: 0;
  top: 18px;
  transform: translateY(-50%);
  pointer-events: none;
  transform-origin: left top;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: text;
  overflow: hidden;
  will-change: transform;
}
.floatingLabel__error {
  color: var(--color-text-error) !important;
}
.floatingLabel__disabled {
  color: var(--color-text-disabled);
}
.floatingLabel__focused {
  transform: translateY(-90%) scale(0.75);
}
.clearIcon {
  position: absolute;
  right: 0;
  top: 18px;
  transform: translateY(-50%);
  cursor: default;
  padding: 0;
  margin: 0;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: var(--img-ic-24-clear-field);
}
