.buttonContent {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 19px;
  cursor: pointer;
}
.buttonContent,
.buttonContent > p {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
}

.profileCartContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.cartButtonIcon {
  pointer-events: none;
  padding: 6px 14px 6px;
}

.cartLabel {
  text-align: center;
}

.cart {
  position: absolute;
  right: 0;
  top: 54px;
  background: var(--color-background-primary);
  border-radius: 12px;
  box-shadow: var(--elevation-8-dp);
}

.cartButton {
  border: none;
  appearance: none;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: none;
}

.dishCount {
  position: absolute;
  box-sizing: border-box;
  padding-left: 4px;
  padding-right: 4px;
  top: 2px;
  left: calc(50% + 2.5px);
  color: var(--color-text-on-color);
  background: var(--color-button-primary-default);
  border-radius: 100px;
  min-width: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Inter;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 16px !important; /* 145.455% */
}


.cartContainer {
  position: relative;
  height: 380px;
  width: 408px;
}
.cartContent {
  height: calc(380px - 117px - 12px);
  overflow: scroll;
  padding: 12px 0 0;
  display: flex;
  flex-direction: column;
}
.submit {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  background: var(--color-background-primary);
  border-radius: 0 0 12px 12px;
}
.submitContainer {
  border-top: 1px solid var(--color-divider-divider);
}
.submitContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 0 20px;
}
.price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.spinnerContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: var(--color-background-primary);
}
