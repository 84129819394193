.header {
  padding: 16px 16px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.side {
  display: flex;
  align-items: center;
  gap: 16px;
}
