.navigation {
  background: var(--color-background-primary);
}

.catalog {
  position: relative;
  padding-bottom: 48px;
}

.catalogContent {
  min-height: 576px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 40px;
}

.cartButtonMobile {
  display: flex;
  position: sticky;
  bottom: 0;
  margin: 0 auto;
  padding: 16px;
  min-width: 102px;
  width: fit-content;
  height: 48px;
}

.cartBtn {
  border-radius: 24px;
  display: flex;
  align-items: center;
}

.dishCount {
  font-family: Inter;
  font-size: 10px !important;
  font-weight: 400;
  line-height: 16px !important;
  letter-spacing: 0.009999999776482582px;
}

.dishPrice {
  font-family: Inter;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: -0.09000000357627869px;
}

.cartLabelGroup {
  display: flex;
  flex-direction: column;
  min-width: fit-content;
}

.cartButtonMobile__button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  margin: 0 auto;
}

.cartButtonMobile__button,
.cartLabelGroup > p {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
}

.cartIcon {
}

.catalogCategory {
  max-width: 1360px;
  width: 100%;
  padding: 0 6px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
}

.catalogCategory:not(:first-child) {
  scroll-margin: calc(62px + 48px + 32px + 8px);
}

.catalogCategory:first-child {
  scroll-margin: calc(118px + 180px + 72px + 24px + 32px);
  padding-top: 24px;
}

.categoryProducts {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 12px;
}

.catalogCard {
  width: calc(100% / 2 - 10px);
  min-width: 148px;
  /* max-width: 224px; */
}

.constructorCatalogCard {
  width: 100%;
}

.videoCatalogCard {
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1;
  max-width: 100%;
}

@media (min-width: 600px) {
  .catalogCategory {
    padding: 0 42px;
  }

  .catalogCategory:not(:first-child) {
    scroll-margin: calc(80px + 84px + 24px + 32px);
  }

  .catalogCategory:first-child {
    scroll-margin: calc(80px + 348px + 32px);
  }

  .catalogCard,
  .videoCatalogCard {
    width: calc(100% / 2 - 21px);
    max-width: 220px;
  }
}

@media (min-width: 650px) {
  .catalogCard,
  .videoCatalogCard {
    width: calc(100% / 3 - 13.4px);
  }
}

@media (min-width: 860px) {
  .catalogCard,
  .videoCatalogCard {
    width: calc(100% / 4 - 15px);
  }
}

@media (min-width: 1024px) {
  .catalogCard,
  .videoCatalogCard {
    width: calc(100% / 5 - 16px);
  }
}

@media (min-width: 500px) and (max-width: 1024px) {
  .catalogCategory {
    padding: 0 16px;
  }
}
