.root {
  position: absolute;
  top: calc(40px + 36px);
  left: calc((100% - 940px) / 2);
  width: 365px;
  z-index: 1;
}
.container {
  width: 100%;
  height: 100%;
  background: var(--color-background-primary);
  box-shadow: var(--elevation-4-dp);
  border-radius: 20px;
  max-height: 580px;
  overflow: scroll;
  -ms-overflow-style: none;
}
.container::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.header {
  padding: 20px 20px 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.colorTitle {
  border-radius: 50%;
  width: 12px;
  height: 12px;
}
.infoItem {
  padding: 20px 20px 0;
}
.infoItem:last-child {
  padding-bottom: 20px;
}
.infoItemContainer {
  padding: 20px;
  /* TODO: поменять цвет на цвет из либы */
  background: #f8f8f8;
  border-radius: 12px;
}
.infoItemContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.infoItemLabel {
  margin-bottom: 16px;
}
.deliveryPriceList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.deliveryPriceItem {
  width: 100%;
}
.deliveryPriceItem:not(:last-child) {
  border-bottom: 1px solid var(--color-divider-divider);
}
.deliveryPriceItemContainer {
  padding: 12px 0;
}
.deliveryPriceItem:last-child > .deliveryPriceItemContainer {
  padding: 11px 0 12px;
}
.deliveryPriceItemContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.containerEnter {
  opacity: 0;
}
.containerEnterActive {
  transition: opacity var(--animation-delay) ease-out;
  opacity: 1;
}
.containerExit {
  opacity: 1;
}
.containerExitActive {
  opacity: 0;
  transition: opacity var(--animation-delay) ease-out;
}

@media (min-width: 1440px) {
  .root {
    left: calc((100% - 1280px) / 2);
  }
}
