.root {
  width: 34px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container {
  position: relative;
  display: block;
  width: 34px;
  height: 11px;
}
.input {
  display: none;
}
.switch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-switch-track-enable-off);
  transition: 0.4s;
  border-radius: 34px;
}
.switch:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 0;
  top: calc(50% - 10px);
  background-color: var(--color-switch-thumb-enable-off);
  box-shadow: var(--elevation-2-dp);
  transition: 0.4s;
  border-radius: 50%;
}
.input:checked + .switch {
  background-color: var(--color-switch-track-enable-on);
}
.input:checked + .switch:before {
  transform: translateX(14px);
  background-color: var(--color-switch-thumb-enable-on);
  box-shadow: var(--elevation-2-dp);
}
