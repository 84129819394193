.card {
  box-sizing: border-box;
  width: 286px;
  padding: 20px;
  position: relative;
  isolation: isolate;
}

.card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  border-radius: 12px;
  border: 2px solid var(--Field-Inactive, #dfdfe5);
}

.deleteButton {
  position: absolute;
  right: 20px;
  top: 20px;
  width: fit-content;
}

.typeIcon {
  height: 24px;
  width: auto;
  object-fit: contain;
  object-position: center;
  pointer-events: none;
}

.mask {
  margin-top: 98px;
  pointer-events: none;
}

@media (min-width: 1024px) {
  .card {
    width: 316px;
  }
  .mask {
    margin-top: 120px;
  }
}
